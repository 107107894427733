import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  TableRow,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
} from "@mui/material";

import {
  Box,
  Button,
  Stack,
  Typography,
  Checkbox,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { updateChannel, createChannel } from "../../store/channels";

const useStyles = makeStyles((theme) => ({
  createNewChannelBtn: {
    width: "194px",
    height: "36px",
    padding: "8px 10px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "29px",
    color: theme.palette.text.third,
    textTransform: "none",
    background: theme.palette.background.third,
    borderRadius: "8px",
  },
  tableChannelName: {
    fontFamily: "Inter",
    fontSize: "14px",
    lineHeight: "29px",
    fontWeight: "500",
    color: theme.palette.text.third,
    textTransform: "capitalize",
  },
  live: {
    background: "#FF0000",
    borderRadius: "5px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "29px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    padding: "0 5px",
    marginRight: "20px",
  },
  inactive: {
    background: theme.palette.badge.primary,
    borderRadius: "5px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "29px",
    color: theme.palette.text.black,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    padding: "0 4px",
    marginRight: "20px",
  },
  modalContent: {
    "&.MuiDialogContent-root": {
      padding: "50px 42px",
    },
  },
  editChannelName: {
    width: "419px",
    height: "51px",
    paddingLeft: "20px",
    background: theme.palette.background.four,
    borderRadius: "15px",
    border: "none",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "29px",
    color: theme.palette.text.secondary,
  },
  editChannelWrapper: {
    marginTop: "98px",
    paddingLeft: "48px",
  },
  editChannelStatusWrapper: {
    width: "419px",
    marginBottom: "36px",
  },
  editChannelStatusHeader: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px",
    color: theme.palette.text.third,
    minWidth: "100px",
    marginRight: "10px",
  },
  editChannelStatusDropdown: {
    width: "300px",
    height: "43px",
    background: theme.palette.background.eleven,
    borderRadius: "15px",
    paddingLeft: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  editChannelStatusSelect: {
    width: "93%",
    background: "transparent",
    border: "none",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "29px",
    color: theme.palette.text.secondary,
  },
  editChannelSaveBtn: {
    width: "130px",
    height: "43px",
    background: "#377DFF",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "29px",
    border: "none",
    color: "white",
    cursor: "pointer",
  },
  editChannelCancelBtn: {
    width: "130px",
    height: "43px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    border: "none",
    lineHeight: "29px",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
}));

function ChannelModal({
  handleModalClose,
  channelModalOpen,
  channels,
  openMode,
  editId,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [mode, setMode] = useState("normal");
  const [editChannel, setEditChannel] = useState({});

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    if (openMode !== "normal") {
      setMode(openMode);
    }
    if (openMode === "create") {
      setEditChannel({
        id: channels.length,
        text: "",
        status: "inactive",
      });
    }
    if (editId !== null) {
      const targetChannel = channels.find((item) => item.id === editId);
      setEditChannel(targetChannel);
    }
  }, [openMode, editId]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = channels.map((n) => n.text);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleEditChannel = (channel) => {
    setMode("edit");
    setEditChannel(channel);
  };
  const handleCancelEditChannel = () => {
    setMode("normal");
  };
  const handleChangeEditChannelInput = (e) => {
    setEditChannel({
      ...editChannel,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditChannelSubmit = (e) => {
    e.preventDefault();
    if (mode === "edit") {
      dispatch(updateChannel(editChannel));
    } else {
      dispatch(createChannel(editChannel));
    }
    setMode("normal");
    setSelected([]);
  };

  const handleCreateChannel = () => {
    setMode("create");
    setEditChannel({
      id: channels.length,
      text: "",
      status: "inactive",
    });
  };
  return (
    <Dialog onClose={handleModalClose} maxWidth="auto" open={channelModalOpen}>
      <DialogContent className={classes.modalContent}>
        <Box className="schedule-media-dlg">
          <Typography
            variant="h5"
            sx={{
              marginBottom: "10px",
              fontFamily: "Inter",
              fontSize: "32px",
              lineHeight: "29px",
              fontWeight: "600",
            }}
          >
            {mode === "normal"
              ? "Channels"
              : mode === "create"
              ? "Add Channel"
              : "Edit Channel"}
          </Typography>
          {mode === "normal" && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ width: "100%", marginBottom: "33px" }}
            >
              <Button
                className={classes.createNewChannelBtn}
                endIcon={<AddIcon />}
                onClick={() => handleCreateChannel()}
              >
                Create New Channel
              </Button>
            </Stack>
          )}
          {mode === "normal" && (
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <TableContainer>
                <Table sx={{ minWidth: 520, marginBottom: "24px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < channels.length
                          }
                          checked={
                            channels.length > 0 &&
                            selected.length === channels.length
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{
                            "aria-label": "select all channels",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        className={classes.tableChannelName}
                      >
                        Channel Name
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableChannelName}
                      >
                        STATUS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {channels.length > 0 &&
                      channels.map((channel) => {
                        const isItemSelected = isSelected(channel.text);
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              handleClick(event, channel.text)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={channel.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": `table-checkbox-${channel.id}`,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={`table-checkbox-${channel.id}`}
                              scope="row"
                              padding="none"
                              className={classes.tableChannelName}
                            >
                              {channel.text}
                            </TableCell>
                            <TableCell align="right">
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                <div className={classes[channel.status]}>
                                  {channel.status}
                                </div>
                                <IconButton
                                  onClick={() => handleEditChannel(channel)}
                                >
                                  <EditOutlinedIcon />
                                </IconButton>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          )}
          {mode !== "normal" && (
            <Box component="form" onSubmit={handleEditChannelSubmit}>
              <Stack
                direction="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                className={classes.editChannelWrapper}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  className={classes.editChannelStatusWrapper}
                >
                  <div className={classes.editChannelStatusHeader}>Label</div>
                  <input
                    name="text"
                    className={classes.editChannelName}
                    value={editChannel.text}
                    onChange={handleChangeEditChannelInput}
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  className={classes.editChannelStatusWrapper}
                >
                  <div className={classes.editChannelStatusHeader}>STATUS</div>
                  <div className={classes.editChannelStatusDropdown}>
                    <select
                      value={editChannel.status}
                      className={classes.editChannelStatusSelect}
                      name="status"
                      onChange={handleChangeEditChannelInput}
                    >
                      <option value="live">Live</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ marginBottom: "18px" }}
                >
                  <button className={classes.editChannelSaveBtn} type="submit">
                    Save
                  </button>
                  <button
                    className={classes.editChannelCancelBtn}
                    onClick={() => handleCancelEditChannel()}
                  >
                    Cancel
                  </button>
                </Stack>
              </Stack>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ChannelModal;
