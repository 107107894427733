import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import moment from "moment";
import {
  HighlightOff,
  Delete,
  Star,
  StarBorder,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { makeStyles } from "@mui/styles";

import "./index.scss";
import {
  Button,
  IconButton,
  Stack,
  Box,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Filtering from "../../components/Filtering";
import { getMedias, updateMedias } from "../../store/medias";
import AddToPlayListModal from "../../components/AddToPlayListModal";
import DeleteMediaConfirmModal from "../../components/DeleteMediaConfirmModal";
import ScheduleModal from "../../components/ScheduleModal";

const useStyles = makeStyles((theme) => ({
  mediaWrapperItem: {
    position: "relative",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    width: "225px",
    height: "126px",
    marginBottom: "22px",
    marginRight: "15px",
    overflow: "hidden",
    cursor: "pointer",

    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  mediaWrapperItemText: {
    position: "absolute",
    height: "36px",
    width: "100%",
    background: "black",
    bottom: "0px",
    left: "0px",
    paddingLeft: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "11px",
    lineHeight: "15px",
    color: "white",
  },
  mediaWrapperItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  mediaWrapperCheckbox: {
    position: "absolute",
    left: "10px",
    top: "8px",
  },
  mediaWrapperFavoriteBtn: {
    position: "absolute",
    left: "40px",
    top: "10px",
    width: "16px",
    height: "16px",

    "& svg": {
      fill: "#D8B70A",
    },
  },
  mediaWrapperHeader: {
    minWidth: "55%",
    maxWidth: "55%",
    height: "30px",
    marginLeft: "auto",
    marginRight: "3%",
    background: theme.palette.background.one,
    borderRadius: "10px",
    marginBottom: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    letterSpacing: "1px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
}));

function MediaLibrary() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);
  const handleClickDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const mediaData = useSelector((state) => state.medias);
  const playListData = useSelector((state) => state.playlist);
  const [editMedia, setEditMedia] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [currentFilter, setCurrentFilter] = useState({
    movie: "",
    status: "",
    startDate: null,
    endDate: null,
    isFavorite: false,
    playList: "",
  });

  const [mediaList, setMediaList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    dispatch(getMedias());
    document.title = "Pro Play Live - Media Library";
  }, []);

  useEffect(() => {
    if (mediaData.medias.length > 0) {
      const customMediaData = mediaData.medias.map((media) => {
        return {
          ...media,
          isChecked: false,
        };
      });
      setMediaList(customMediaData.slice());
    }
  }, [mediaData]);

  const filteredMediaList = useMemo(() => {
    let filteredList = mediaList;

    if (currentFilter.playList !== "") {
      const targetIds = playListData.selectedPlayListMedias;
      filteredList = filteredList.filter((item) => targetIds.includes(item.id));
    } else {
      filteredList = mediaList;
    }

    if (currentFilter.movie) {
      filteredList = mediaList.filter(
        (item) =>
          item.description
            .replace(/\s/g, "")
            .toLowerCase()
            .includes(currentFilter.movie) ||
          item.title
            .replace(/\s/g, "")
            .toLowerCase()
            .includes(currentFilter.movie)
      );
    }
    if (currentFilter.status) {
      filteredList = filteredList.filter(
        (item) => item.status === currentFilter.status
      );
    }

    if (currentFilter.startDate && !currentFilter.endDate) {
      filteredList = filteredList.filter((item) =>
        moment(item.createdOn).isSameOrAfter(moment(currentFilter.startDate))
      );
    } else if (!currentFilter.startDate && currentFilter.endDate) {
      filteredList = filteredList.filter((item) =>
        moment(item.createdOn).isSameOrBefore(moment(currentFilter.endDate))
      );
    } else if (currentFilter.startDate && currentFilter.endDate) {
      filteredList = filteredList.filter((item) =>
        moment(item.createdOn).isBetween(
          moment(currentFilter.startDate),
          moment(currentFilter.endDate),
          "[]"
        )
      );
    }
    if (currentFilter.isFavorite) {
      filteredList = filteredList.filter((item) => item.isFavorite);
    }
    return filteredList;
  }, [currentFilter, mediaList]);

  const isFiltered = useMemo(() => {
    return (
      currentFilter.movie ||
      currentFilter.status ||
      currentFilter.startDate !== null ||
      currentFilter.endDate !== null
    );
  }, [currentFilter]);

  const medias = _.groupBy(filteredMediaList || mediaList, "createdOn");

  const handleClearFilter = () => {
    setCurrentFilter({
      movie: "",
      status: "",
      startDate: null,
      endDate: null,
    });
  };

  const handleChange = (event, media) => {
    const customMediaList = _.cloneDeep(mediaList);
    const targetMedia = customMediaList.find((item) => item.id === media.id);
    targetMedia.isChecked = event.target.checked;
    setMediaList(customMediaList);
  };

  const handleFavoriteClick = (media) => {
    const customMediaList = _.cloneDeep(mediaList);
    const targetMedia = customMediaList.find((item) => item.id === media.id);
    targetMedia.isFavorite = !targetMedia.isFavorite;
    setMediaList(customMediaList);
    dispatch(updateMedias(customMediaList));
  };

  const handleCheckedFavorite = () => {
    const customMediaList = mediaList.slice();
    customMediaList.forEach((item) => {
      if (item.isChecked) {
        item.isFavorite = true;
      }
    });

    setMediaList(customMediaList);
    dispatch(updateMedias(customMediaList));
  };

  const handleRemove = () => {
    setOpenConfirmModal(true);
  };

  const handleConfirmModalClose = () => {
    setOpenConfirmModal(false);
  };

  const handleConfirmDelete = () => {
    const customMediaList = mediaList.slice();
    const filtered = customMediaList.filter((item) => {
      return !item.isChecked;
    });
    setMediaList(filtered);
    dispatch(updateMedias(filtered));
  };

  const handleSelectAll = () => {
    const customMediaList = mediaList.slice();
    customMediaList.forEach((item) => {
      item.isChecked = true;
    });
    setMediaList(customMediaList);
  };

  const isRemoveButtonDisabled = useMemo(() => {
    let flag = true;
    if (mediaList.length > 0) {
      mediaList.forEach((item) => {
        if (item.isChecked) {
          flag = flag && false;
        } else {
          flag = flag && true;
        }
      });
    }
    return flag;
  }, [mediaList]);

  const selectedIds = useMemo(() => {
    if (mediaList.length > 0) {
      const targetIds = mediaList
        .filter((item) => item.isChecked)
        .map((item) => item.id);
      return targetIds;
    }
    return [];
  }, [mediaList]);

  const handleAddToPlayList = () => {
    handleCloseDropdown();
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleMediaClick = (media) => {
    setEditMedia(media);
    setEditModalOpen(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="media-library-page">
        <div className="media-library-page-media-container">
          <div className="media-library-page-media-container-tool">
            <Button
              id="demo-customized-button"
              aria-controls={openDropdown ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openDropdown ? "true" : undefined}
              variant="text"
              disableElevation
              onClick={handleClickDropDown}
              startIcon={<KeyboardArrowDown />}
            >
              Actions
            </Button>
            <Menu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openDropdown}
              onClose={handleCloseDropdown}
            >
              <MenuItem
                onClick={handleAddToPlayList}
                disabled={isRemoveButtonDisabled}
              >
                Add to Playlist
              </MenuItem>
              <MenuItem
                onClick={handleRemove}
                disabled={isRemoveButtonDisabled}
              >
                Delete Selected
              </MenuItem>
            </Menu>
            <Button
              variant="text"
              sx={{ textTransform: "none" }}
              onClick={() => handleSelectAll()}
            >
              Select All
            </Button>
            <IconButton
              onClick={() => handleRemove()}
              disabled={isRemoveButtonDisabled}
            >
              <Delete />
            </IconButton>
            <IconButton onClick={() => handleCheckedFavorite()}>
              <Star />
            </IconButton>
          </div>
          {mediaList.length > 0 &&
            Object.keys(medias).map((mediaKey, index) => (
              <div className="media-wrapper" key={index}>
                <div className={classes.mediaWrapperHeader}>
                  {moment().isSame(moment(mediaKey), "day")
                    ? "Today"
                    : moment(mediaKey).format("ll")}
                </div>
                <Box component="form" sx={{ width: "100%" }}>
                  <Stack className={classes.mediaWrapperItems} direction="row">
                    {medias[mediaKey].map((media, index) => (
                      <Stack
                        className={classes.mediaWrapperItem}
                        key={index}
                        onClick={() => handleMediaClick(media)}
                      >
                        <input
                          type="checkbox"
                          className={classes.mediaWrapperCheckbox}
                          checked={media.isChecked}
                          onChange={(e) => handleChange(e, media)}
                        />
                        <IconButton
                          className={classes.mediaWrapperFavoriteBtn}
                          onClick={() => handleFavoriteClick(media)}
                        >
                          {media.isFavorite ? <Star /> : <StarBorder />}
                        </IconButton>
                        <img src={media.thumbnailUrl} alt="" />
                        <div className={classes.mediaWrapperItemText}>
                          {media.description}
                        </div>
                      </Stack>
                    ))}
                  </Stack>
                </Box>
              </div>
            ))}
        </div>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          className="media-library-page-filter"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "29px",
                marginRight: "20px",
              }}
            >
              Filter Media
            </Typography>
            {isFiltered && (
              <IconButton onClick={() => handleClearFilter()}>
                <HighlightOff />
              </IconButton>
            )}
          </Stack>
          <Filtering
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
          />
        </Stack>
        <AddToPlayListModal
          handleModalClose={handleModalClose}
          playListModalOpen={openModal}
          selectedIds={selectedIds}
        />
        <DeleteMediaConfirmModal
          handleModalClose={handleConfirmModalClose}
          openConfirmModal={openConfirmModal}
          onConfirmDelete={handleConfirmDelete}
        />
        <ScheduleModal
          currentMedia={editMedia}
          onModalClose={() => setEditModalOpen(false)}
          isEdit
          // handleSchedule={handleSchedule}
          modalOpen={editModalOpen}
        />
      </div>
    </LocalizationProvider>
  );
}

export default MediaLibrary;
