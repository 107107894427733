import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { playList } from "../data/playList";

const initialState = {
  isLoading: false,
  playList: [],
  currentPlayListId: null,
  currentPlayListMedias: null,
  selectedPlayListMedias: [],
  error: null,
};

const isLoading = (action) => {
  return [
    "playList/pending",
    "playList/mediaIds/pending",
    "playList/add/pending",
    "playList/update/medias/pending",
    "playList/medias/pending",
    "playList/addMediasToPlayList/pending",
  ].includes(action.type);
};

const isFinishLoading = (action) => {
  return [
    "playList/fulfilled",
    "playList/rejected",
    "playList/addMediasToPlayList/fulfilled",
    "playList/addMediasToPlayList/rejected",
    "playList/mediaIds/fulfilled",
    "playList/mediaIds/rejected",
    "playList/add/fulfilled",
    "playList/add/rejected",
    "playList/update/medias/fulfilled",
    "playList/update/medias/rejected",
    "playList/medias/fulfilled",
    "playList/medias/rejected",
  ].includes(action.type);
};

const isRejected = (action) => {
  return [
    "playList/rejected",
    "playList/addMediasToPlayList/rejected",
    "playList/mediaIds/rejected",
    "playList/add/rejected",
    "playList/update/medias/rejected",
    "playList/medias/rejected",
  ].includes(action.type);
};

export const getPlayList = createAsyncThunk(
  "playList",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          if (getState().playlist.playList.length > 0) {
            resolve(getState().playlist.playList.slice());
          } else {
            resolve(playList);
          }
        }, 500)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const getPlayListMediaIds = createAsyncThunk(
  "playList/mediaIds",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          const currentPlayList = getState().playlist.playList.find(
            (item) => item.id === values
          );
          resolve(currentPlayList.medias);
        }, 500)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const addPlayList = createAsyncThunk(
  "playList/add",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          const currentPlayList = getState().playlist.playList;
          let addingPlayList = {
            name: values,
            medias: [],
          };
          if (!currentPlayList || currentPlayList.length < 1) {
            addingPlayList.id = 0;
          } else {
            addingPlayList.id = currentPlayList.length;
          }
          resolve(addingPlayList);
        }, 500)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const addMediasToPlayList = createAsyncThunk(
  "playList/addMediasToPlayList",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          resolve(values);
        }, 500)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const getPlayListMedias = createAsyncThunk(
  "playList/medias",
  async (values, { rejectWithValue, getState }) => {
    try {
      const playList = getState().playlist.playList;
      const mediaIds = playList.find((item) => item.id === values).medias;
      let mediaSource = [];
      let targetMedias = [];
      if (values !== getState().playlist.currentPlayListId) {
        mediaSource = getState().medias.medias;
        mediaIds.forEach((mediaId) => {
          targetMedias.push(mediaSource.find((item) => item.id === mediaId));
        });
      } else {
        targetMedias = getState().playlist.currentPlayListMedias.slice();
      }

      const data = await new Promise((resolve) =>
        setTimeout(() => {
          resolve({
            targetMedias,
            currentPlayListId: values,
          });
        }, 500)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const updatePlayListMedias = createAsyncThunk(
  "playList/update/medias",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          let newPlayList = getState().playlist.playList.slice();
          const index = newPlayList.findIndex(
            (item) => item.id === values.currentPlayListId
          );
          const sortedIds = values.sortedItems.map((item) => item.id);
          newPlayList[index] = {
            ...newPlayList[index],
            medias: sortedIds,
          };
          resolve({
            newPlayList,
            newMediaList: values.sortedItems,
          });
        }, 500)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const playListSlice = createSlice({
  name: "playList",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPlayList.fulfilled, (state, action) => {
        state.playList = action.payload;
      })
      .addCase(addPlayList.fulfilled, (state, action) => {
        const customPlayList = state.playList.slice();
        customPlayList.push(action.payload);
        state.playList = customPlayList;
      })
      .addCase(addMediasToPlayList.fulfilled, (state, action) => {
        const customPlayList = state.playList.slice();
        const targetPlayList = customPlayList.find(
          (item) => item.id === action.payload.playListId
        );
        targetPlayList.medias = [
          ...targetPlayList.medias,
          ...action.payload.medias,
        ];
        state.playList = customPlayList;
      })
      .addCase(getPlayListMediaIds.fulfilled, (state, action) => {
        state.selectedPlayListMedias = action.payload;
      })
      .addCase(getPlayListMedias.fulfilled, (state, action) => {
        state.currentPlayListMedias = action.payload.targetMedias;
        state.currentPlayListId = action.payload.currentPlayListId;
      })
      .addCase(updatePlayListMedias.fulfilled, (state, action) => {
        state.playList = action.payload.newPlayList;
        state.currentPlayListMedias = action.payload.newMediaList;
      })
      .addMatcher(isLoading, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addMatcher(isFinishLoading, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default playListSlice.reducer;
