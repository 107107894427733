import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import {
  Box,
  Stack,
  Typography,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import { addMediasToPlayList, getPlayList } from "../../store/playlist";

const useStyles = makeStyles({
  modalContent: {
    "&.MuiDialogContent-root": {
      padding: "36px 40px",
      width: "606px",
      height: "auto",
    },
  },
  addPlayListName: {
    width: "250px",
    height: "43px",
    paddingLeft: "13px",
    background: "#EFEFEF",
    borderRadius: "15px",
    border: "none",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "29px",
    color: "#4E5D78˝",
    marginBottom: "43px",
  },
  editChannelSaveBtn: {
    width: "130px",
    height: "43px",
    background: "#377DFF",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "29px",
    border: "none",
    color: "white",
    cursor: "pointer",
    "&:disabled": {
      background: "lightgray",
      cursor: "not-allowed",
    },
  },
  editChannelCancelBtn: {
    width: "130px",
    height: "43px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    border: "none",
    lineHeight: "29px",
    color: "#B0B7C3",
    cursor: "pointer",
  },
});

function AddToPlayListModal({
  handleModalClose,
  playListModalOpen,
  selectedIds,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [playList, setPlayList] = useState([]);
  const [currentPlayList, setCurrentPlayList] = useState("");
  const playListData = useSelector((state) => state.playlist);

  useEffect(() => {
    dispatch(getPlayList());
  }, []);

  useEffect(() => {
    if (playListData.playList.length > 0) {
      setPlayList(playListData.playList);
    }
  }, [playListData]);

  const handleCancel = (e) => {
    e.preventDefault();
    handleModalClose();
    setCurrentPlayList("");
  };

  const handleChange = (event) => {
    setCurrentPlayList(event.target.value);
  };

  const handleAddToPlayList = (e) => {
    e.preventDefault();
    const payload = {
      playListId: currentPlayList,
      medias: selectedIds,
    };
    dispatch(addMediasToPlayList(payload));
    handleModalClose();
    setCurrentPlayList("");
  };

  return (
    <Dialog onClose={handleModalClose} maxWidth="auto" open={playListModalOpen}>
      <DialogContent className={classes.modalContent}>
        <Box>
          <Typography
            variant="h5"
            sx={{
              marginBottom: "14px",
              fontFamily: "Inter",
              fontSize: "32px",
              lineHeight: "29px",
              fontWeight: "600",
              color: "#323B4B",
            }}
          >
            Add To Playlist
          </Typography>
          <Typography
            variant="h5"
            sx={{
              marginBottom: "46px",
              fontFamily: "Inter",
              fontSize: "16px",
              lineHeight: "29px",
              fontWeight: "600",
              color: "#B0B7C3",
            }}
          >
            Add the selected videos to a playlist below.
          </Typography>
          <Box component="form" onSubmit={handleAddToPlayList}>
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.editChannelWrapper}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "18px",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  lineHeight: "29px",
                  fontWeight: "500",
                  color: "#4E5D78",
                }}
              >
                Playlist
              </Typography>
              {playList.length > 0 && (
                <FormControl sx={{ marginBottom: "64px", width: "250px" }}>
                  <Select
                    id="demo-simple-select"
                    value={currentPlayList}
                    onChange={handleChange}
                  >
                    <MenuItem value="" disabled>
                      Select PlayList
                    </MenuItem>
                    {playList.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ marginBottom: "18px" }}
              >
                <button
                  className={classes.editChannelSaveBtn}
                  type="submit"
                  disabled={!currentPlayList}
                >
                  Save
                </button>
                <button
                  className={classes.editChannelCancelBtn}
                  onClick={(e) => handleCancel(e)}
                >
                  Cancel
                </button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AddToPlayListModal;
