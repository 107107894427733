import VideoImage from "../assets/images/video.png";

export const mediaList = [
  {
    id: 0,
    title: "Video1.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 1",
    createdOn: "2022-04-26",
    status: "ready",
    isFavorite: false,
  },
  {
    id: 1,
    title: "Video2.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 2",
    createdOn: "2022-04-26",
    status: "ready",
    isFavorite: false,
  },
  {
    id: 2,
    title: "Video3.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 3",
    createdOn: "2022-04-26",
    status: "ready",
    isFavorite: false,
  },
  {
    id: 3,
    title: "Video4.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 4",
    createdOn: "2022-04-26",
    status: "ready",
    isFavorite: false,
  },
  {
    id: 4,
    title: "Video5.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 5",
    createdOn: "2022-04-26",
    status: "ready",
    isFavorite: false,
  },
  {
    id: 5,
    title: "Video6.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 6",
    createdOn: "2021-11-10",
    status: "processing",
  },
  {
    id: 6,
    title: "Video7.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 7",
    createdOn: "2021-11-10",
    status: "processing",
  },
  {
    id: 7,
    title: "Video6.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 8",
    createdOn: "2021-11-10",
    status: "processing",
  },
  {
    id: 8,
    title: "Video9.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 9",
    createdOn: "2021-11-10",
    status: "processing",
  },
  {
    id: 9,
    title: "Video10.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 10",
    createdOn: "2021-11-10",
    status: "processing",
  },
  {
    id: 10,
    title: "Video11.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 11",
    createdOn: "2021-11-10",
    status: "processing",
  },
  {
    id: 11,
    title: "Video12.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 12",
    createdOn: "2021-11-10",
    status: "processing",
  },
  {
    id: 12,
    title: "Video13.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 13",
    createdOn: "2021-11-09",
    status: "processing",
  },
  {
    id: 13,
    title: "Video14.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 14",
    createdOn: "2021-11-09",
    status: "processing",
  },
  {
    id: 14,
    title: "Video15.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 15",
    createdOn: "2021-11-09",
    status: "processing",
  },
  {
    id: 15,
    title: "Video16.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 16",
    createdOn: "2021-11-06",
    status: "processing",
  },
  {
    id: 16,
    title: "Video17.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 17",
    createdOn: "2021-11-06",
    status: "processing",
  },
  {
    id: 17,
    title: "Video18.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 18",
    createdOn: "2021-11-06",
    status: "processing",
  },
  {
    id: 18,
    title: "Video19.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 19",
    createdOn: "2021-11-06",
    status: "processing",
  },
  {
    id: 19,
    title: "Video20.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 20",
    createdOn: "2021-11-02",
    status: "processing",
  },
  {
    id: 20,
    title: "Video21.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 21",
    createdOn: "2021-11-02",
    status: "processing",
  },
  {
    id: 21,
    title: "Video22.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 22",
    createdOn: "2021-11-01",
    status: "processing",
  },
  {
    id: 22,
    title: "Video23.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 23",
    createdOn: "2021-11-01",
    status: "processing",
  },
  {
    id: 23,
    title: "Video24.mp4",
    thumbnailUrl: VideoImage,
    contentUrl: null,
    userId: 1,
    duration: 6400,
    description: "Video 24",
    createdOn: "2021-11-01",
    status: "processing",
  },
];
