import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles } from "@mui/styles";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import {
  Badge,
  IconButton,
  Stack,
  Typography,
  Popover,
  LinearProgress,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";

import Logo from "../../assets/images/logo.png";
import ThemeSwitchImage from "../../assets/images/switch.png";
import ProfileImage from "../../assets/images/profile.png";

import "./index.scss";
import UploadModal from "../UploadModal";
import { getChannels } from "../../store/channels";
import { updateMode } from "../../store/theme";

import ChannelModal from "../ChannelModal";

const useStyles = makeStyles((theme) => ({
  header: {
    "&.MuiAppBar-root": {
      backgroundColor: theme.palette.background.primary,
      boxShadow: "none",
      height: "100px",
      display: "flex",
      justifyContent: "flex-end",
      zIndex: 1199,
    },
  },
  headerContainer: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerLogo: {
    width: "250px",
    height: "100px",
    borderRight: "1px solid #F3F3F3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "116px",
      height: "80px",
    },
  },
  headerTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "23px",
    color: theme.palette.text.primary,
    marginLeft: "40px",
  },
  channelDropdownBtn: {
    width: "243px",
    height: "43px",
    background: theme.palette.dropdown.primary,
    borderRadius: "15px",
    padding: "11px 15px",
    marginRight: "30px",
  },
  channelDropdownBtnContent: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-start",
    textTransform: "none",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "29px",
    color: theme.palette.text.secondary,
  },
  channelItemText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "29px",
    color: theme.palette.text.third,
    textTransform: "capitalize",
  },
  channelName: {
    color: theme.palette.text.third,
    marginLeft: "5px",
    textTransform: "capitalize",
  },
  channelItemWrapper: {
    borderRadius: "8px",
    padding: "9px 7px",
    width: "293px",
    height: "36px",
    minWidth: "293px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  activeChannelItem: {
    background: theme.palette.dropdown.secondary,
  },
  live: {
    background: theme.palette.error.main,
    borderRadius: "5px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "29px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    padding: "0 5px",
    height: "21px",
    marginRight: "20px",
  },
  inactive: {
    background: theme.palette.badge.primary,
    borderRadius: "5px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "29px",
    color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    padding: "0 4px",
    height: "21px",
    marginRight: "20px",
  },
  createNewChannelBtn: {
    width: "194px",
    height: "36px",
    padding: "8px 10px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "29px",
    color: theme.palette.text.third,
    textTransform: "none",
    background: theme.palette.background.third,
    borderRadius: "8px",
  },
  seeAllBtn: {
    textTransform: "none",
  },
  uploadBtn: {
    width: "130px",
    height: "43px",
    background: "#377DFF",
    borderRadius: "15px",
    marginRight: "32px",
    color: theme.palette.text.white,
  },
  badge: {
    marginRight: "45px",
    "& svg": {
      fill: "#B0B7C3",
    },
  },
  profileWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  profileDropdownBtn: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  profileImageWrapper: {
    width: "47px",
    height: "47px",
    borderRadius: "50%",
    overflow: "hidden",
    marginRight: "7px",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  profileName: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "29px",
    color: theme.palette.text.third,
    marginRight: "10px",
  },
  profileDropdown: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: `1px solid ${theme.palette.text.secondary}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5px",
    padding: "0",
    "& svg": {
      fill: theme.palette.text.secondary,
    },
  },
  profileMenuHeader: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "29px",
    color: theme.palette.text.one,
    padding: "30px 35px",
    borderBottom: `1px solid ${theme.palette.background.three}`,
    width: "100%",
  },
  profileMainWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "16px 40px 16px 60px",
    width: "100%",
  },
  profileMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "visible",
    marginRight: "25px",
  },
  profileMainImageWrapper: {
    width: "48px",
    height: "48px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  profileMainImageSvg: {
    border: "1.5px solid white",
    position: "absolute",
    bottom: "-9px",
    right: "-6px",
    width: "28px",
    height: "28px",
    borderRadius: "50%",
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: "26px",
      height: "26px",
      fill: "#377DFF",
      boxShadow: "0px 4px 4px rgba(176, 183, 195, 0.17)",
    },
  },
  profileInfo: {
    flex: 1,
  },
  profileInfoName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
  profileInfoNameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    color: theme.palette.text.third,
  },
  profileDesc: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    color: theme.palette.text.secondary,
  },
  profileMenuItem: {
    width: "100%",
    padding: "9px 0 13px 60px",
  },
  profileMenuItemText: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    color: theme.palette.text.third,
  },
  profileMenuItemStorage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "14px",
  },
  profileMenuItemTextHeader: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    color: theme.palette.text.third,
    marginRight: "67px",
  },
  profileMenuItemTextValue: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "29px",
    color: theme.palette.text.secondary,
  },
  profileMenuItemDesc: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    color: theme.palette.text.secondary,
  },
}));

const Header = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const channelsData = useSelector((state) => state.channels);
  const themeData = useSelector((state) => state.theme);
  const [currentChannel, setCurrentChannel] = useState("");
  const [channelModalOpen, setChannelModalOpen] = useState(false);
  const [channels, setChannels] = useState([]);
  const [openMode, setOpenMode] = useState("normal");
  const [editChannelId, setEditChannelId] = useState(null);
  const [invisible, setInvisible] = useState(false);
  const [mode, setMode] = useState(themeData.mode);
  const [uploadOpen, setUploadOpen] = useState(false);

  // const handleBadgeVisibility = () => {
  //   setInvisible(!invisible);
  // };

  const currentLocation = location.pathname.replace("/", "");

  const headerTitles = {
    schedule: "Schedule",
    "media-library": "Media Library",
    playlist: "PlayList",
    monitize: "Monitize",
  };
  const currentHeaderTitle = headerTitles[currentLocation];
  const [channelAnchor, setChannelAnchor] = useState(null);
  const [profileAnchor, setProfileAnchor] = useState(null);
  const [storage, setStorage] = useState(75);
  const channelOpen = Boolean(channelAnchor);
  const profileOpen = Boolean(profileAnchor);
  const handleChannelClick = (event) => {
    setChannelAnchor(event.currentTarget);
  };
  const handleProfileClick = (event) => {
    setProfileAnchor(event.currentTarget);
  };
  const handleCloseChannel = () => {
    setChannelAnchor(null);
  };
  const handleCloseProfile = () => {
    setProfileAnchor(null);
  };
  const handleChangeChannel = (e) => {
    setCurrentChannel(e.target.value);
  };
  const profilePopupId = profileOpen ? "profile-popup" : undefined;
  const handleClickAddChannel = () => {
    setOpenMode("create");
    setEditChannelId(null);
    setChannelModalOpen(true);
  };

  const handleShowChannelModal = () => {
    setChannelModalOpen(true);
  };

  const handleChannelModalClose = () => {
    setChannelModalOpen(false);
  };

  const handleOpenEditChannelModal = (channel) => {
    setOpenMode("edit");
    setEditChannelId(channel.id);
    setChannelModalOpen(true);
  };

  useEffect(() => {
    dispatch(getChannels());
  }, []);

  useEffect(() => {
    if (channelsData.channels) {
      setChannels(channelsData.channels);
    }
  }, [channelsData]);

  useEffect(() => {
    if (themeData.mode) {
      if (mode !== themeData.mode) {
        setMode(themeData.mode);
      }
    }
  }, [themeData]);

  const handleChangeMode = () => {
    if (mode === "light") {
      dispatch(updateMode("dark"));
    } else {
      dispatch(updateMode("light"));
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      className={classes.header}
    >
      <Container maxWidth="100%" className={classes.headerContainer}>
        <Stack direction="row" alignItems="center">
          <Toolbar disableGutters className={classes.headerLogo}>
            <img src={Logo} alt="" />
          </Toolbar>
          <Typography className={classes.headerTitle}>
            {currentHeaderTitle}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ marginRight: "50px" }}
        >
          <Button
            id="channel-button"
            className={classes.channelDropdownBtn}
            aria-controls={channelOpen ? "channel-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={channelOpen ? "true" : undefined}
            onClick={handleChannelClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            <span className={classes.channelDropdownBtnContent}>
              Channel:{" "}
              <span className={classes.channelName}>{currentChannel}</span>
            </span>
          </Button>
          <Menu
            id="channel-menu"
            anchorEl={channelAnchor}
            open={channelOpen}
            onClose={handleCloseChannel}
            MenuListProps={{
              "aria-labelledby": "channel-button",
            }}
          >
            {channels.map((channel) => (
              <MenuItem onClick={handleCloseChannel} key={channel.id}>
                <Stack
                  direction="row"
                  className={`${classes.channelItemWrapper} ${
                    currentChannel == channel.text
                      ? classes.activeChannelItem
                      : ""
                  }`}
                >
                  <FormControlLabel
                    className={classes.channelItemText}
                    control={
                      <Checkbox
                        checked={currentChannel === channel.text}
                        value={channel.text}
                        onChange={handleChangeChannel}
                      />
                    }
                    label={channel.text}
                  />
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <div className={classes[channel.status]}>
                      {channel.status}
                    </div>
                    <IconButton
                      onClick={() => handleOpenEditChannelModal(channel)}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </MenuItem>
            ))}
            <MenuItem>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Button
                  className={classes.createNewChannelBtn}
                  endIcon={<AddIcon />}
                  onClick={() => handleClickAddChannel()}
                >
                  Create New Channel
                </Button>
                <Button
                  className={classes.seeAllBtn}
                  variant="text"
                  onClick={() => handleShowChannelModal()}
                >
                  See All
                </Button>
              </Stack>
            </MenuItem>
          </Menu>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            className={classes.uploadBtn}
            onClick={() => setUploadOpen(true)}
          >
            Upload
          </Button>
          <Badge
            color="error"
            variant="dot"
            invisible={invisible}
            className={classes.badge}
          >
            <NotificationsIcon />
          </Badge>
          <div
            style={{ marginRight: "28px", cursor: "pointer" }}
            onClick={() => handleChangeMode()}
          >
            <img src={ThemeSwitchImage} alt="" width="27px" height="25px" />
          </div>
          <div className={classes.profileWrapper}>
            <Button
              aria-describedby={profilePopupId}
              onClick={handleProfileClick}
            >
              <div className={classes.profileImageWrapper}>
                <img src={ProfileImage} alt="" />
              </div>
              <div className={classes.profileName}>Alex Richards</div>
              <div className={classes.profileDropdown}>
                <KeyboardArrowDownIcon />
              </div>
            </Button>
            <Popover
              id={profilePopupId}
              open={profileOpen}
              anchorEl={profileAnchor}
              onClose={handleCloseProfile}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Stack
                alignItems="flex-start"
                justifyContent="flex-start"
                sx={{ width: "420px" }}
              >
                <div className={classes.profileMenuHeader}>My Account</div>
                <div className={classes.profileMainWrapper}>
                  <div className={classes.profileMain}>
                    <div className={classes.profileMainImageWrapper}>
                      <img src={ProfileImage} alt="" />
                    </div>
                    <div className={classes.profileMainImageSvg}>
                      <AddCircleIcon />
                    </div>
                  </div>
                  <div className={classes.profileInfo}>
                    <div className={classes.profileInfoName}>
                      <div className={classes.profileInfoNameText}>
                        Alex Richard
                      </div>
                      <IconButton sx={{ padding: 0 }}>
                        <EditIcon />
                      </IconButton>
                    </div>
                    <div className={classes.profileDesc}>
                      Admin - Ninja of all things
                    </div>
                  </div>
                </div>
                <div className={classes.profileMenuItem}>
                  <div className={classes.profileMenuItemText}>
                    Notifications
                  </div>
                  <div className={classes.profileMenuItemDesc}>
                    Adjust how you want to see notes
                  </div>
                </div>
                <div className={classes.profileMenuItem}>
                  <div className={classes.profileMenuItemText}>Plans</div>
                  <div className={classes.profileMenuItemDesc}>
                    See and update your plans
                  </div>
                </div>
                <div className={classes.profileMenuItem}>
                  <div className={classes.profileMenuItemStorage}>
                    <div className={classes.profileMenuItemTextHeader}>
                      Storage
                    </div>
                    <div className={classes.profileMenuItemTextValue}>
                      1.55TB / 2TB
                    </div>
                  </div>
                  <div
                    className={classes.profileMenuItemStorageProgress}
                    style={{ width: "221px" }}
                  >
                    <LinearProgress
                      variant="determinate"
                      value={storage}
                      sx={{ width: "100%" }}
                      color="primary"
                    />
                  </div>
                </div>
                <div className={classes.profileMenuItem}>
                  <div className={classes.profileMenuItemText}>
                    Account Settings
                  </div>
                  <div className={classes.profileMenuItemDesc}>
                    View and update your account settings
                  </div>
                </div>
                <div className={classes.profileMenuItem}>
                  <div className={classes.profileMenuItemText}>Log out</div>
                  <div className={classes.profileMenuItemDesc}>
                    Come back soon
                  </div>
                </div>
              </Stack>
            </Popover>
          </div>
        </Stack>
      </Container>
      {channelModalOpen && (
        <ChannelModal
          handleModalClose={handleChannelModalClose}
          channelModalOpen={channelModalOpen}
          channels={channels}
          openMode={openMode}
          editId={editChannelId}
        />
      )}
      <UploadModal
        handleModalClose={() => setUploadOpen(false)}
        modalOpen={uploadOpen}
      />
    </AppBar>
  );
};

export default Header;
