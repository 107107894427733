export const playList = [
  {
    id: 0,
    name: "Play List 1",
    medias: [0, 1, 2, 3, 4, 5],
  },
  {
    id: 1,
    name: "Play List 2",
    medias: [1, 2, 3, 4, 5, 6],
  },
  {
    id: 2,
    name: "Play List 3",
    medias: [2, 3, 4, 5, 6, 7],
  },
  {
    id: 3,
    name: "Play List 4",
    medias: [3, 4, 5, 6, 7, 8],
  },
  {
    id: 4,
    name: "Play List 5",
    medias: [4, 5, 6, 7, 8, 9],
  },
  {
    id: 5,
    name: "Play List 6",
    medias: [5, 6, 7, 8, 9, 10],
  },
  {
    id: 6,
    name: "Play List 7",
    medias: [6, 7, 8, 9, 10, 11],
  },
  {
    id: 7,
    name: "Play List 8",
    medias: [7, 8, 9, 10, 11, 12],
  },
  {
    id: 8,
    name: "Play List 9",
    medias: [8, 9, 10, 11, 12, 13],
  },
  {
    id: 9,
    name: "Play List 10",
    medias: [9, 10, 11, 12, 13, 14],
  },
  {
    id: 10,
    name: "Play List 11",
    medias: [10, 11, 12, 13, 14, 15],
  },
  {
    id: 11,
    name: "Play List 12",
    medias: [11, 12, 13, 14, 15, 16],
  },
];
