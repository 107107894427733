import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { channels } from "../data/channels";

const initialState = {
  isLoading: false,
  channels: [],
  error: null,
};

const isLoading = (action) => {
  return [
    "channels/pending",
    "channels/update/pending",
    "channels/create/pending",
  ].includes(action.type);
};

const isFinishLoading = (action) => {
  return [
    "channels/fulfilled",
    "channels/rejected",
    "channels/update/fulfilled",
    "channels/update/rejected",
    "channels/create/fulfilled",
    "channels/create/rejected",
  ].includes(action.type);
};

const isRejected = (action) => {
  return [
    "channels/rejected",
    "channels/update/rejected",
    "channels/create/rejected",
  ].includes(action.type);
};

export const getChannels = createAsyncThunk(
  "channels",
  async (values, { rejectWithValue }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          resolve(channels);
        }, 1000)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const updateChannel = createAsyncThunk(
  "channels/update",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          const newChannels = getState().channels.channels.slice();
          const index = newChannels.findIndex((item) => item.id === values.id);
          newChannels[index] = {
            ...values,
          };
          resolve(newChannels);
        }, 1000)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const createChannel = createAsyncThunk(
  "channels/create",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          const newChannels = getState().channels.channels.slice();
          newChannels.push(values);
          resolve(newChannels);
        }, 1000)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const getChannelsSlice = createSlice({
  name: "channels",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getChannels.fulfilled, (state, action) => {
        state.channels = action.payload;
      })
      .addCase(updateChannel.fulfilled, (state, action) => {
        state.channels = action.payload;
      })
      .addCase(createChannel.fulfilled, (state, action) => {
        state.channels = action.payload;
      })
      .addMatcher(isLoading, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addMatcher(isFinishLoading, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default getChannelsSlice.reducer;
