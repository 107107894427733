import * as React from "react";
import { Provider } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

import Layout from "./components/Layout";
import MediaLibrary from "./pages/MediaLibrary";
import Monitize from "./pages/Monitize";
import PlayList from "./pages/PlayList";
import Schedule from "./pages/Schedule";

import store from "./store";
import theme from "./theme";

import "./App.scss";

function App() {
  return (
      <Provider store={store}>
        <Routes>
          <Route index element={<Navigate to="/schedule" />} />
          <Route element={<Layout />}>
            <Route path="schedule" element={<Schedule />} />
            <Route path="media-library" element={<MediaLibrary />} />
            <Route path="playlist" element={<PlayList />} />
            <Route path="monitize" element={<Monitize />} />
          </Route>
        </Routes>
      </Provider>
  );
}

export default App;
