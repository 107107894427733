import React, { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Filtering from "../Filtering";

function FilterModal({
  handleFilterClose,
  openFilter,
  currentFilter,
  setCurrentFilter,
}) {
  const handleSetCurrentFilter = (filter) => {
    setCurrentFilter(filter);
    handleFilterClose();
  };
  return (
    <Dialog onClose={handleFilterClose} open={openFilter}>
      <DialogTitle>Filter Media</DialogTitle>
      <DialogContent>
        <Filtering
          currentFilter={currentFilter}
          setCurrentFilter={handleSetCurrentFilter}
        />
      </DialogContent>
    </Dialog>
  );
}

export default FilterModal;
