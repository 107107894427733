import { configureStore } from "@reduxjs/toolkit";

import channels from "./channels";
import keyList from "./keyList";
import medias from "./medias";
import playlist from "./playlist";
import theme from "./theme";

export default configureStore({
  reducer: {
    channels,
    medias,
    playlist,
    keyList,
    theme,
  },
});
