import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { channels } from "../data/channels";

const initialState = {
  isLoading: false,
  mode: "light",
};

const isLoading = (action) => {
  return ["theme"].includes(action.type);
};

const isFinishLoading = (action) => {
  return ["theme", "theme/rejected"].includes(action.type);
};

const isRejected = (action) => {
  return ["theme/rejected"].includes(action.type);
};

export const updateMode = createAsyncThunk(
  "theme",
  async (values, { rejectWithValue }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          resolve(values);
        }, 1000)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(updateMode.fulfilled, (state, action) => {
        state.mode = action.payload;
      })
      .addMatcher(isLoading, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected, (state, action) => {
        state.isLoading = false;
      })
      .addMatcher(isFinishLoading, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default themeSlice.reducer;
