export const channels = [
  {
    id: 0,
    text: "channel A",
    status: "live",
  },
  {
    id: 1,
    text: "channel B",
    status: "live",
  },
  {
    id: 2,
    text: "channel C",
    status: "inactive",
  },
];
