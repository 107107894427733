import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { Box, Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { makeStyles } from "@mui/styles";

import UploadImage from "../../assets/images/upload.png";
import VideoImage from "../../assets/images/video.png";

import { getPlayList } from "../../store/playlist";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    "&.MuiDialogContent-root": {
      padding: "51px 30px",
      width: "900px",
      height: "auto",
    },
  },
  dropzoneWrapper: {
    width: "100%",
    height: "294px",
    background: theme.palette.background.twelve,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginBottom: "26px",
    borderRadius: "15px",
    overflow: "hidden",
  },
  dropzone: {
    width: "600px",
    height: "235px",
    border: `1.5px dashed ${theme.palette.border.primary}`,
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      marginBottom: "26px",
    },
  },
  droppedTable: {
    "& thead tr": {
      background: theme.palette.background.twelve,
      borderRadius: "15px",
      overflow: "hidden",
    },
  },
  videoInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& img": {
      width: "107px",
      height: "60px",
      marginRight: "23px",
    },
  },
  videoInfoText: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "29px",
    textTransform: "capitalize",
    color: theme.palette.text.third,
  },
  playlistWrapper: {
    position: "relative",
    "& select": {
      width: "150px",
      height: "25px",
      background: theme.palette.background.thirteen,
      borderRadius: "15px",
      paddingLeft: "17px",
      border: "none",
      color: theme.palette.text.secondary,
    },
  },
}));

function UploadModal({ handleModalClose, modalOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [playListInfo, setPlayListInfo] = useState("");
  const [files, setFiles] = useState([]);
  const playListData = useSelector((state) => state.playlist);

  const [playList, setPlayList] = useState([]);

  useEffect(() => {
    dispatch(getPlayList());
  }, []);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      let customFiles = files.slice();
      customFiles = [...customFiles, ...acceptedFiles];
      setFiles(customFiles);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (playListData.playList.length > 0) {
      setPlayList(playListData.playList);
    }
  }, [playListData]);

  return (
    <Dialog onClose={handleModalClose} maxWidth="auto" open={modalOpen}>
      <DialogContent className={classes.modalContent}>
        <Box>
          <Typography
            variant="h5"
            sx={{
              marginBottom: "56px",
              fontFamily: "Inter",
              fontSize: "32px",
              lineHeight: "29px",
              fontWeight: "600",
            }}
          >
            Video Upload
          </Typography>
          <Box className={classes.dropzoneWrapper}>
            <div {...getRootProps({ className: classes.dropzone })}>
              <input {...getInputProps()} />
              <img src={UploadImage} alt="" />
              <p style={{ margin: 0 }}>
                Drag and Drop or{" "}
                <span style={{ color: "#377DFF" }}>Browse</span> to upload
              </p>
            </div>
          </Box>
          {files.length > 0 && (
            <Box className={classes.droppedTable}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{files.length} Video(s)</TableCell>
                    <TableCell>PLAYLIST</TableCell>
                    <TableCell>STATUS</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <div className={classes.videoInfo}>
                          <img src={VideoImage} alt="" />
                          <div className={classes.videoInfoText}>
                            {file.path}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        {playList.length > 0 && (
                          <div className={classes.playlistWrapper}>
                            <select>
                              {playList.map((item, index) => (
                                <option value={item.id} key={index}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Edit</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default UploadModal;
