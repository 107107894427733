export const keys = [
  {
    id: 0,
    text: "National",
    color: "#229B36",
  },
  {
    id: 1,
    text: "Music Videos",
    color: "#1D4FCE",
  },
  {
    id: 2,
    text: "Local Programs",
    color: "#DD6634",
  },
  {
    id: 3,
    text: "News",
    color: "#9B2296",
  },
];
