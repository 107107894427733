import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { addPlayList } from "../../store/playlist";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    "&.MuiDialogContent-root": {
      padding: "36px 40px",
      width: "606px",
      height: "auto",
    },
  },
  addPlayListName: {
    width: "250px",
    height: "43px",
    paddingLeft: "13px",
    background: theme.palette.background.eleven,
    borderRadius: "15px",
    border: "none",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "29px",
    color: theme.palette.text.third,
    marginBottom: "43px",
  },
  editChannelSaveBtn: {
    width: "130px",
    height: "43px",
    background: "#377DFF",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "29px",
    border: "none",
    color: "white",
    cursor: "pointer",
    "&:disabled": {
      background: "lightgray",
      cursor: "not-allowed",
    },
  },
  editChannelCancelBtn: {
    width: "130px",
    height: "43px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    border: "none",
    lineHeight: "29px",
    color: theme.palette.text.third,
    cursor: "pointer",
  },
}));

function PlaylistModal({ handleModalClose, playListModalOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [playListInfo, setPlayListInfo] = useState("");

  const handleCancelEditChannel = (e) => {
    e.preventDefault();
    handleModalClose();
  };
  const handleChangeEditChannelInput = (e) => {
    setPlayListInfo(e.target.value);
  };

  const handleAddPlayList = (e) => {
    e.preventDefault();
    dispatch(addPlayList(playListInfo));
    handleModalClose();
  };

  return (
    <Dialog onClose={handleModalClose} maxWidth="auto" open={playListModalOpen}>
      <DialogContent className={classes.modalContent}>
        <Box>
          <Typography
            variant="h5"
            sx={{
              marginBottom: "14px",
              fontFamily: "Inter",
              fontSize: "32px",
              lineHeight: "29px",
              fontWeight: "600",
            }}
          >
            Add Playlist
          </Typography>
          <Typography
            variant="h5"
            sx={{
              marginBottom: "46px",
              fontFamily: "Inter",
              fontSize: "16px",
              lineHeight: "29px",
              fontWeight: "600",
            }}
          >
            Give your playlist a name below.
          </Typography>
          <Box component="form" onSubmit={handleAddPlayList}>
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.editChannelWrapper}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "18px",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  lineHeight: "29px",
                  fontWeight: "500",
                }}
              >
                Playlist name
              </Typography>
              <input
                name="text"
                className={classes.addPlayListName}
                value={playListInfo}
                onChange={handleChangeEditChannelInput}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ marginBottom: "18px" }}
              >
                <button
                  className={classes.editChannelSaveBtn}
                  type="submit"
                  disabled={!playListInfo}
                >
                  Save
                </button>
                <button
                  className={classes.editChannelCancelBtn}
                  onClick={(e) => handleCancelEditChannel(e)}
                >
                  Cancel
                </button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default PlaylistModal;
