import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import RestoreIcon from "@mui/icons-material/Restore";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

const drawerWidth = 250;

const useStyles = makeStyles({
  toolbar: {
    "&.MuiToolbar-root": {
      height: "100px",
    },
  },
  drawer: {
    "&.MuiDrawer-root": {
      zIndex: 1198,
    },
    "& .MuiPaper-root": {
      borderRight: "1px solid #F3F3F3",
    },
  },
  menuItem: {
    "&.MuiListItem-root": {
      width: "calc(100% - 34px)",
      margin: "0 17px",
      borderRadius: "15px",
      marginBottom: "5px",
      "& span": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "29px",
        color: "#8A94A6",
      },
    },
    "&.MuiListItem-root.Mui-selected span": {
      color: "#377DFF",
    },
  },
});

function LeftNav() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [currentItem, setCurrentItem] = useState(null);
  const menuItems = [
    { title: "Schedule", icon: <RestoreIcon />, link: "/schedule" },
    {
      title: "Media Library",
      icon: <LibraryBooksIcon />,
      link: "/media-library",
    },
    { title: "Playlist", icon: <LocalMoviesIcon />, link: "/playlist" },
    { title: "Monitize", icon: <InventoryIcon />, link: "/monitize" },
  ];

  const handleItemClick = (item, index) => {
    setCurrentItem(index);
    navigate(item.link);
  };

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar className={classes.toolbar} />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {menuItems.map((item, index) => (
            <ListItem
              button
              key={index}
              className={classes.menuItem}
              selected={index === currentItem}
              onClick={() => handleItemClick(item, index)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}

export default LeftNav;
