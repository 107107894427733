import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import InputAdornment from "@mui/material/InputAdornment";

import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import SearchIcon from "@mui/icons-material/Search";
import { Star, StarBorder } from "@mui/icons-material";

import { makeStyles } from "@mui/styles";

import {
  Box,
  IconButton,
  Button,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { getPlayList, getPlayListMediaIds } from "../../store/playlist";

import "./index.scss";

const useStyles = makeStyles((theme) => ({
  filterBtn: {
    width: "130px",
    height: "43px",
    background: "#377DFF",
    color: theme.palette.text.primary,
    borderRadius: "15px",
    textTransform: "none",
  },
  filterClearBtn: {
    width: "130px",
    height: "43px",
    color: theme.palette.text.third,
    borderRadius: "15px",
    textTransform: "none",
  },
  filterFavoriteBtn: {
    width: "16px",
    height: "16px",
    marginLeft: "30px",

    "& svg": {
      fill: "#D8B70A",
    },
  },
  statusBtnReady: {
    width: "142px",
    height: "26px",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "29px",
    cursor: "pointer",
    background: theme.palette.background.seven,
    color: "#38CB89",
    marginRight: "15px",
  },
  statusBtnProcessing: {
    width: "142px",
    height: "26px",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "29px",
    cursor: "pointer",
    background: theme.palette.background.seven,
    color: "#FF5630",
  },
  readyActive: {
    background: theme.palette.background.nine,
    color: "white",
  },
  processingActive: {
    background: theme.palette.background.ten,
    color: "white",
  },
}));

function Filtering({ currentFilter, setCurrentFilter }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const initialFilter = {
    movie: "",
    status: "",
    startDate: null,
    endDate: null,
    isFavorite: false,
    playList: "",
  };

  const [filter, setFilter] = useState(initialFilter);
  const playListData = useSelector((state) => state.playlist);
  const [playList, setPlayList] = useState([[]]);

  useEffect(() => {
    if (playListData.playList.length > 0) {
      setPlayList(playListData.playList);
    }

    if (playListData.playList !== playList) {
      setPlayList(playListData.playList);
    }
  }, [playListData]);

  useEffect(() => {
    dispatch(getPlayList());
  }, []);

  useEffect(() => {
    if (currentFilter !== filter) {
      setFilter({
        ...currentFilter,
      });
    }
  }, [currentFilter]);

  const handleFilterTextChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };
  const handleFilterDateChange = (name, value) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };
  const handleFilterStatus = (status) => {
    setFilter({
      ...filter,
      status,
    });
  };
  const handleClickFilter = () => {
    setCurrentFilter(filter);
  };

  const handleFavoriteClick = () => {
    setFilter({
      ...filter,
      isFavorite: !filter.isFavorite,
    });
  };

  const handleSelectPlaylist = (event) => {
    setFilter({
      ...filter,
      playList: event.target.value,
    });
    if (event.target.value) {
      dispatch(getPlayListMediaIds(event.target.value));
    }
  };

  const handleClickFilterAll = () => {
    setFilter(initialFilter);
    setCurrentFilter(initialFilter);
  };

  return (
    <Stack>
      <Box sx={{ marginTop: "20px" }}>
        <TextField
          id="input-with-icon-textfield"
          label="Search"
          name="movie"
          fullWidth
          value={filter.movie}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          onChange={handleFilterTextChange}
        />
      </Box>
      <Stack direction="column" sx={{ marginTop: "22px " }}>
        <Typography variant="subtitle1" sx={{ marginBottom: "12px" }}>
          Status
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ marginBottom: "12px" }}
        >
          <div
            className={`${classes.statusBtnReady} ${
              filter.status === "ready" ? classes.readyActive : ""
            }`}
            onClick={() => handleFilterStatus("ready")}
          >
            Ready
          </div>
          <div
            className={`${classes.statusBtnProcessing} ${
              filter.status === "processing" ? classes.processingActive : ""
            }`}
            onClick={() => handleFilterStatus("processing")}
          >
            Processing
          </div>
          <IconButton
            className={classes.filterFavoriteBtn}
            onClick={() => handleFavoriteClick()}
          >
            {filter.isFavorite ? <Star /> : <StarBorder />}
          </IconButton>
        </Stack>
        <Stack sx={{ marginBottom: "10px" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Playlist</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter.playList}
              label="Playlist"
              onChange={handleSelectPlaylist}
            >
              <MenuItem value="">Select PlayList</MenuItem>
              {playList.length > 0 &&
                playList.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
        <Typography variant="subtitle1" sx={{ marginBottom: "10px" }}>
          Date Range
        </Typography>
        <Stack direction="row">
          <Stack sx={{ marginRight: "10px" }}>
            <DesktopDatePicker
              label="Start Date"
              inputFormat="MM/dd/yyyy"
              value={filter.startDate}
              name="startDate"
              onChange={(value) => handleFilterDateChange("startDate", value)}
              renderInput={(params) => <TextField {...params} />}
              sx={{ marginRight: "5px" }}
            />
          </Stack>
          <Stack>
            <DesktopDatePicker
              label="End Date"
              name="endDate"
              inputFormat="MM/dd/yyyy"
              value={filter.endDate}
              onChange={(value) => handleFilterDateChange("endDate", value)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ marginTop: "44px" }}
        >
          <Button
            className={classes.filterBtn}
            variant="contained"
            onClick={() => handleClickFilter()}
          >
            Filter
          </Button>
          <Button
            className={classes.filterClearBtn}
            variant="text"
            onClick={() => handleClickFilterAll()}
          >
            Clear All
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Filtering;
