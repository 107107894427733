import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChromePicker } from "react-color";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { Box, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { makeStyles } from "@mui/styles";

import {
  getKeyList,
  createKey,
  updateKeyList,
  removeKey,
} from "../../store/keyList";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    "&.MuiDialogContent-root": {
      padding: "36px 40px",
      width: "606px",
      height: "600px",
    },
  },
  addPlayListName: {
    width: "250px",
    height: "51px",
    paddingLeft: "20px",
    background: theme.palette.background.four,
    borderRadius: "15px",
    border: "none",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "29px",
    color: theme.palette.text.secondary,
    marginBottom: "43px",
  },
  editChannelSaveBtn: {
    width: "130px",
    height: "43px",
    background: "#377DFF",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "29px",
    border: "none",
    color: theme.palette.text.white,
    cursor: "pointer",
    "&:disabled": {
      background: "lightgray",
      cursor: "not-allowed",
    },
    "& svg": {
      fill: theme.palette.text.white,
      width: "17px",
      height: "17px",
      marginRight: "14px",
    },
  },
  editChannelCancelBtn: {
    width: "130px",
    height: "43px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    border: "none",
    lineHeight: "29px",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  keyItemColor: {
    width: "35px",
    height: "35px",
    borderRadius: "5px",
    marginRight: "53px",
  },
  keyItemText: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "26px",
    lineHeight: "29px",
    color: theme.palette.text.one,
    width: "270px",
    textAlign: "left",
  },
  keyItemEditBtn: {
    width: "78px",
    height: "34px",
    borderRadius: "15px",
    background: theme.palette.background.five,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: "38px",
    "& svg": {
      width: "14px",
      height: "14px",
      marginRight: "3.5px",
    },
  },
  removeKeyBtn: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fill: theme.palette.background.six,
      width: "24px",
      height: "24px",
    },
  },
  keyItemEditBtnText: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "29px",
    color: theme.palette.text.two,
  },
  editKeyInput: {
    width: "420px",
    height: "52px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    background: theme.palette.background.four,
    borderRadius: "15px",
    border: "none",
    padding: "0 20px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "29px",
    color: theme.palette.text.one,
    "&::placeholder": {
      color: theme.palette.text.secondary,
    },
    marginBottom: "44px",
  },
}));

function KeyControlModal({ handleModalClose, openKeyModal }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const keyListData = useSelector((state) => state.keyList);
  const [keyList, setKeyList] = useState([]);
  const [mode, setMode] = useState("view");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [currentKeyInfo, setCurrentKeyInfo] = useState({
    id: 0,
    text: "",
    color: "#000",
  });

  useEffect(() => {
    dispatch(getKeyList());
    setMode("view");
  }, []);

  useEffect(() => {
    if (keyListData.keyList.length > 0) {
      setKeyList(keyListData.keyList);
    }
  }, [keyListData]);

  const handleCancel = (e) => {
    e.preventDefault();
    setMode("view");
  };

  const handleKeySubmit = (e) => {
    const payload = {
      ...currentKeyInfo,
      id: keyList.length,
    };
    e.preventDefault();
    if (mode === "add") {
      dispatch(createKey(payload));
    } else if (mode === "edit") {
      dispatch(updateKeyList(currentKeyInfo));
    }

    setMode("view");
    setCurrentKeyInfo({
      ...currentKeyInfo,
      text: "",
      color: "#000",
    });
  };

  const handleAddKey = () => {
    setMode("add");
  };

  const handleEditKeyTextChange = (e) => {
    setCurrentKeyInfo({ ...currentKeyInfo, text: e.target.value });
  };

  const handleKeyColorChange = (color, event) => {
    setCurrentKeyInfo({ ...currentKeyInfo, color: color.hex });
  };

  const handleEditKey = (targetKey) => {
    setCurrentKeyInfo(targetKey);
    setMode("edit");
  };

  const handleRemoveKey = (targetKey) => {
    dispatch(removeKey(targetKey));
  };

  const handleRemoveKeyInEdit = (e) => {
    e.preventDefault();
    dispatch(removeKey(currentKeyInfo));
    setMode("view");
  };

  return (
    <Dialog onClose={handleModalClose} maxWidth="auto" open={openKeyModal}>
      <DialogContent className={classes.modalContent}>
        <Box>
          <Typography
            variant="h5"
            sx={{
              marginBottom: "7px",
              fontFamily: "Inter",
              fontSize: "32px",
              lineHeight: "29px",
              fontWeight: "600",
            }}
          >
            {mode !== "add" ? "Edit Key" : "Add Key"}
          </Typography>
          {mode === "view" && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <button
                className={classes.editChannelSaveBtn}
                onClick={() => handleAddKey()}
              >
                <AddIcon />
                Add Key
              </button>
            </Stack>
          )}
          {mode !== "view" && (
            <Stack sx={{ marginTop: "66px", marginBottom: "43px" }}>
              <input
                type="text"
                placeholder="Key Name"
                className={classes.editKeyInput}
                value={currentKeyInfo.text}
                onChange={(e) => handleEditKeyTextChange(e)}
              />
              <div>
                <div
                  style={{
                    background: currentKeyInfo.color,
                    width: "35px",
                    height: "35px",
                    borderRadius: "5px",
                  }}
                  onClick={() => setShowColorPicker(true)}
                />
                {showColorPicker ? (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "2",
                    }}
                  >
                    <div
                      style={{
                        position: "fixed",
                        top: "0px",
                        right: "0px",
                        bottom: "0px",
                        left: "0px",
                      }}
                      onClick={() => setShowColorPicker(false)}
                    />
                    <ChromePicker
                      color={currentKeyInfo.color}
                      onChange={handleKeyColorChange}
                    />
                  </div>
                ) : null}
                <Typography
                  style={{
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "29px",
                    marginTop: "6px",
                  }}
                >
                  Key Color
                </Typography>
              </div>
            </Stack>
          )}
          {mode === "view" && keyList.length > 0 && (
            <Stack
              sx={{ marginTop: "38px", maxHeight: "400px", overFlowY: "auto" }}
            >
              {keyList.map((keyItem, index) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  key={index}
                  sx={{ marginBottom: "35px" }}
                >
                  <div
                    className={classes.keyItemColor}
                    style={{ background: keyItem.color }}
                  />
                  <div className={classes.keyItemText}>{keyItem.text}</div>
                  <div
                    className={classes.keyItemEditBtn}
                    onClick={() => handleEditKey(keyItem)}
                  >
                    <EditIcon />
                    <div className={classes.keyItemEditBtnText}>Edit</div>
                  </div>
                  <div
                    onClick={() => handleRemoveKey(keyItem)}
                    className={classes.removeKeyBtn}
                  >
                    <DeleteIcon />
                  </div>
                </Stack>
              ))}
            </Stack>
          )}
          {mode !== "view" && (
            <Box component="form" onSubmit={handleKeySubmit}>
              <Stack
                direction="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                className={classes.editChannelWrapper}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ marginBottom: "18px" }}
                >
                  <button
                    className={classes.editChannelSaveBtn}
                    type="submit"
                    disabled={!currentKeyInfo.text}
                  >
                    Save
                  </button>
                  <button
                    className={classes.editChannelCancelBtn}
                    onClick={(e) => handleCancel(e)}
                  >
                    Cancel
                  </button>
                  {mode === "edit" && (
                    <button
                      className={classes.editChannelCancelBtn}
                      onClick={(e) => handleRemoveKeyInEdit(e)}
                    >
                      Remove
                    </button>
                  )}
                </Stack>
              </Stack>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default KeyControlModal;
