import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";

import _ from "lodash";
import moment from "moment";

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import { Stack, IconButton, Button } from "@mui/material";
import {
  Search,
  HighlightOff,
  Menu,
  Close,
  Delete,
  FormatListBulleted,
} from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  getPlayList,
  getPlayListMedias,
  updatePlayListMedias,
} from "../../store/playlist";
import { getMedias } from "../../store/medias";
import FilterModal from "../../components/FilterModal";
import PlaylistModal from "../../components/PlaylistModal";

const useStyles = makeStyles((theme) => ({
  playListPageContainer: {
    width: "100%",
    height: "calc(100vh - 100px)",
    position: "relative",
  },
  playListsWrapper: {
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    margin: "auto",
    minHeight: "154px",
    maxHeight: "154px",
    height: "154px",
    overflowY: "auto",
    justifyContent: "flex-start",
    flexDirection: "row",
    background: theme.palette.background.two,
    borderRadius: "10px",
    padding: "20px 70px",
    width: "97%",
    overflowX: "auto",
    marginBottom: "36px",
  },
  playListItem: {
    width: "254px",
    height: "51px",
    background: theme.palette.background.one,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "24px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    color: theme.palette.text.third,
    marginRight: "17px",
    marginBottom: "10px",
    cursor: "pointer",
  },
  playListCurrentItem: {
    background: "#377DFF",
    color: "white",
  },
  playListBottomWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "97%",
    margin: "auto",
    height: "calc(100% - 250px)",
  },
  mediaListWrapper: {
    width: "calc(50% - 25px)",
    height: "100%",
  },
  currentPlaylistMediasWrapper: {
    width: "calc(50% - 25px)",
    height: "100%",
  },
  currentPlaylistMediasWrapperTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "23px",
    color: theme.palette.text.one,
    marginRight: "12px",
  },
  currentPlaylistMedias: {
    height: "calc(100% - 76px)",
    width: "100%",
    borderRadius: "10px",
    background: theme.palette.background.two,
    padding: "42px 34px",
    overflowY: "auto",
  },
  currentPlayListMediaImage: {
    width: "150px",
    height: "84px",
    background: theme.palette.dropdown.primary,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    overflow: "hidden",
    marginRight: "40px",
    pointerEvents: "none",
  },
  currentPlayListMediaTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "23px",
    color: theme.palette.text.third,
    marginRight: "40px",
  },
  currentPlayListMediaDuration: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "23px",
    color: theme.palette.text.third,
  },
  currentPlayListMediaContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "20px",
    "& svg": {
      fill: "#C1C7D0",
    },
  },
  currentPlayListMediaContainerCollapse: {
    background: theme.palette.background.three,
    height: "30px",
    marginBottom: "9px",
    cursor: "pointer",
  },
  mediaListFilterWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "28px",
  },
  mediaListFilterTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "23px",
    color: theme.palette.text.one,
  },
  mediaListFilterHeader: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "29px",
    color: theme.palette.text.one,
    marginRight: "5px",
  },
  mediaGroupHeader: {
    width: "100%",
    height: "36px",
    background: theme.palette.background.one,
    borderRadius: "10px",
    marginBottom: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    letterSpacing: "1px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  mediaCardWrapper: {
    marginBottom: "32px",
  },
  mediaCardItems: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  mediaCardItem: {
    position: "relative",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    width: "calc(25% - 10px)",
    height: "auto",
    marginRight: "10px",
    marginBottom: "10px",
    overflow: "hidden",
    display: "flex",
    cursor: "pointer",
  },
  mediaCardItemText: {
    position: "absolute",
    bottom: "2px",
    left: "5px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "8px",
    lineHeight: "15px",
    color: "white",
  },
  mediaListContainer: {
    height: "calc(100% - 76px)",
    overflowY: "auto",
  },
  currentPlaylistMediasWrapperHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "28px",
  },
  blue: {
    fill: "#377DFF",
  },
  black: {
    fill: theme.palette.text.primary,
  },
}));

const PlayListToolbar = styled(Stack)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "97%",
  margin: " auto",
  marginBottom: "20px",
});

function PlayList() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const playListData = useSelector((state) => state.playlist);
  const [playList, setPlayList] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [mediaList, setMediaList] = useState([]);
  const [currentPlaylistMedias, setCurrentPlaylistMedias] = useState(null);
  const [addingMedia, setAddingMedia] = useState({});
  const [openAddPlaylistModal, setOpenAddPlayListModal] = useState(false);
  const [listMode, setListMode] = useState("expand");

  const [currentFilter, setCurrentFilter] = useState({
    movie: "",
    status: "",
    startDate: null,
    endDate: null,
    isFavorite: false,
    playList: "",
  });

  const [currentPlayList, setCurrentPlayList] = useState(null);
  const mediaData = useSelector((state) => state.medias);

  useEffect(() => {
    dispatch(getPlayList());
    dispatch(getMedias());
    document.title = "Pro Play Live - Playlist";
  }, []);

  useEffect(() => {
    if (currentPlayList !== null) {
      dispatch(getPlayListMedias(currentPlayList));
    }
  }, [currentPlayList]);

  useEffect(() => {
    if (playListData.playList.length > 0) {
      setPlayList(playListData.playList);
    }

    if (playListData.playList !== playList) {
      setPlayList(playListData.playList);
    }

    setCurrentPlaylistMedias(playListData.currentPlayListMedias);
  }, [playListData]);

  useEffect(() => {
    if (mediaData.medias.length > 0) {
      setMediaList(mediaData.medias);
    }
  }, [mediaData]);

  const filteredMediaList = useMemo(() => {
    let filteredList = mediaList;

    if (currentFilter.playList !== "") {
      const targetIds = playListData.selectedPlayListMedias;
      filteredList = filteredList.filter((item) => targetIds.includes(item.id));
    } else {
      filteredList = mediaList;
    }

    if (currentFilter.movie) {
      filteredList = mediaList.filter(
        (item) =>
          item.description
            .replace(/\s/g, "")
            .toLowerCase()
            .includes(currentFilter.movie) ||
          item.title
            .replace(/\s/g, "")
            .toLowerCase()
            .includes(currentFilter.movie)
      );
    }
    if (currentFilter.status) {
      filteredList = filteredList.filter(
        (item) => item.status === currentFilter.status
      );
    }

    if (currentFilter.startDate && !currentFilter.endDate) {
      filteredList = filteredList.filter((item) =>
        moment(item.createdOn).isSameOrAfter(moment(currentFilter.startDate))
      );
    } else if (!currentFilter.startDate && currentFilter.endDate) {
      filteredList = filteredList.filter((item) =>
        moment(item.createdOn).isSameOrBefore(moment(currentFilter.endDate))
      );
    } else if (currentFilter.startDate && currentFilter.endDate) {
      filteredList = filteredList.filter((item) =>
        moment(item.createdOn).isBetween(
          moment(currentFilter.startDate),
          moment(currentFilter.endDate),
          "[]"
        )
      );
    }

    if (currentFilter.isFavorite) {
      filteredList = filteredList.filter((item) => item.isFavorite);
    }
    return filteredList;
  }, [currentFilter, mediaList]);

  const isFiltered = useMemo(() => {
    return (
      currentFilter.movie ||
      currentFilter.status ||
      currentFilter.startDate !== null ||
      currentFilter.endDate !== null
    );
  }, [currentFilter]);

  const medias = _.groupBy(filteredMediaList, "createdOn");

  const handleClearFilter = () => {
    setCurrentFilter({
      movie: "",
      status: "",
      startDate: null,
      endDate: null,
    });
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  const onSortEnd = (oldIndex, newIndex) => {
    const sortedItems = arrayMoveImmutable(
      currentPlaylistMedias.slice(),
      oldIndex,
      newIndex
    );
    setCurrentPlaylistMedias(sortedItems);

    const payload = {
      currentPlayListId: currentPlayList,
      sortedItems: sortedItems,
    };
    dispatch(updatePlayListMedias(payload));
  };

  const handleRemoveMedia = (id) => {
    const customMedias = currentPlaylistMedias.slice();
    const targetIndex = customMedias.findIndex((item) => item.id === id);
    if (targetIndex > -1) {
      customMedias.splice(targetIndex, 1);
    }
    setCurrentPlaylistMedias(customMedias);
  };

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const customItems = currentPlaylistMedias.slice();
    customItems.push(addingMedia);
    setCurrentPlaylistMedias(customItems);

    const payload = {
      currentPlayListId: currentPlayList,
      sortedItems: customItems,
    };
    dispatch(updatePlayListMedias(payload));
  };

  const handleMediaDragStart = (e, media) => {
    setAddingMedia(media);
  };

  const handleRemovePlayList = () => {};
  const handleAddPlayList = () => {
    setOpenAddPlayListModal(true);
  };

  const handleAddPlayListModalClose = () => {
    setOpenAddPlayListModal(false);
  };

  const handleChangeListMode = (mode) => {
    setListMode(mode);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack className={classes.playListPageContainer}>
        <PlayListToolbar>
          <IconButton
            onClick={() => handleRemovePlayList()}
            disabled={currentPlayList === null}
          >
            <Delete />
          </IconButton>
          <Button
            variant="text"
            onClick={() => handleAddPlayList()}
            sx={{ textTransform: "none" }}
          >
            Add Playlist
          </Button>
        </PlayListToolbar>
        <Stack className={classes.playListsWrapper}>
          {playList.length > 0 &&
            playList.map((item) => (
              <Stack
                className={`${classes.playListItem} ${
                  currentPlayList === item.id
                    ? classes.playListCurrentItem
                    : null
                }`}
                key={item.id}
                onClick={() => setCurrentPlayList(item.id)}
              >
                {item.name}
              </Stack>
            ))}
        </Stack>
        <Stack className={classes.playListBottomWrapper}>
          <div className={classes.mediaListWrapper}>
            <Stack className={classes.mediaListFilterWrapper}>
              <div className={classes.mediaListFilterTitle}>Media</div>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  onClick={() => setOpenFilter(true)}
                  sx={{ cursor: "pointer" }}
                >
                  <div className={classes.mediaListFilterHeader}>
                    Filter Media
                  </div>
                  <Search />
                </Stack>
                {isFiltered && (
                  <IconButton onClick={() => handleClearFilter()}>
                    <HighlightOff />
                  </IconButton>
                )}
              </Stack>
            </Stack>
            <div className={classes.mediaListContainer}>
              {Object.keys(medias).map((mediaKey, index) => (
                <div className={classes.mediaCardWrapper} key={index}>
                  <div className={classes.mediaGroupHeader}>
                    {moment().isSame(moment(mediaKey), "day")
                      ? "Today"
                      : moment(mediaKey).format("ll")}
                  </div>
                  <div className={classes.mediaCardItems}>
                    {medias[mediaKey].map((media, index) => (
                      <div
                        className={classes.mediaCardItem}
                        key={index}
                        // onClick={() => handleMediaClick(media)}
                        onDragStart={(e) => handleMediaDragStart(e, media)}
                        draggable
                      >
                        <img
                          src={media.thumbnailUrl}
                          style={{ width: "100%", height: "100%" }}
                          alt=""
                        />
                        <div className={classes.mediaCardItemText}>
                          {media.description}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.currentPlaylistMediasWrapper}>
            <div className={classes.currentPlaylistMediasWrapperHeader}>
              <div className={classes.currentPlaylistMediasWrapperTitle}>
                Playlist Items
              </div>
              <div className={`${classes.currentPlaylistActions}`}>
                <IconButton
                  sx={{ marginRight: "5px" }}
                  onClick={() => handleChangeListMode("collapse")}
                >
                  <FormatListBulleted
                    className={
                      listMode === "collapse" ? classes.blue : classes.black
                    }
                  />
                </IconButton>
                <IconButton onClick={() => handleChangeListMode("expand")}>
                  <Menu
                    className={
                      listMode === "expand" ? classes.blue : classes.black
                    }
                  />
                </IconButton>
              </div>
            </div>
            <div
              className={classes.currentPlaylistMedias}
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => handleDragOver(e)}
            >
              {currentPlaylistMedias && currentPlaylistMedias.length > 0 ? (
                <SortableList
                  allowDrag
                  onSortEnd={onSortEnd}
                  className={classes.currentPlaylistMediasSortable}
                  draggedItemClassName="dragged"
                >
                  {currentPlaylistMedias.map((media, index) => {
                    return (
                      <SortableItem key={index}>
                        <div
                          className={`${
                            classes.currentPlayListMediaContainer
                          } ${
                            listMode === "collapse"
                              ? classes.currentPlayListMediaContainerCollapse
                              : null
                          }`}
                        >
                          <IconButton
                            onClick={() => handleRemoveMedia(media.id)}
                          >
                            <Close />
                          </IconButton>
                          <IconButton
                            sx={{ marginRight: "30px", cursor: "move" }}
                          >
                            <Menu />
                          </IconButton>
                          {listMode === "expand" && (
                            <div className={classes.currentPlayListMediaImage}>
                              <img
                                src={media.thumbnailUrl}
                                style={{ width: "100%", height: "100%" }}
                                alt=""
                              />
                            </div>
                          )}
                          <div className={classes.currentPlayListMediaTitle}>
                            {media.title}
                          </div>
                          <div className={classes.currentPlayListMediaDuration}>
                            Duration:{" "}
                            {moment.utc(media.duration * 1000).format("mm:ss")}
                          </div>
                        </div>
                      </SortableItem>
                    );
                  })}
                </SortableList>
              ) : (
                <div
                  style={{
                    width: "auto",
                    margin: "auto",
                    textAlign: "center",
                    color: "#C1C7D0",
                    fontSize: "22px",
                    lineHeight: "25px",
                    fontWeight: "600",
                  }}
                >
                  Select a Playlist.
                </div>
              )}
            </div>
          </div>
        </Stack>
        <FilterModal
          handleFilterClose={handleFilterClose}
          openFilter={openFilter}
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
        />
        <PlaylistModal
          handleModalClose={handleAddPlayListModalClose}
          playListModalOpen={openAddPlaylistModal}
        />
      </Stack>
    </LocalizationProvider>
  );
}

export default PlayList;
