import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import moment from "moment";
import { Search, HighlightOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { styled, alpha } from "@mui/material/styles";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  Appointments,
  AppointmentForm,
  AppointmentTooltip,
  TodayButton,
  Toolbar,
  DayView,
  EditRecurrenceMenu,
  DateNavigator,
  ViewSwitcher,
  DragDropProvider,
} from "@devexpress/dx-react-scheduler-material-ui";

import "./index.scss";
import ScheduleModal from "../../components/ScheduleModal";
import FilterModal from "../../components/FilterModal";
import { IconButton, Stack } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { getMedias } from "../../store/medias";
import { getKeyList } from "../../store/keyList";
import KeyControlModal from "../../components/KeyControlModal";

const PREFIX = "Demo";

const classes = {
  todayCell: `${PREFIX}-todayCell`,
  weekendCell: `${PREFIX}-weekendCell`,
  today: `${PREFIX}-today`,
  weekend: `${PREFIX}-weekend`,
};
const useStyles = makeStyles((theme) => ({
  mediaHeaderText: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "29px",
    color: theme.palette.text.one,
  },
  mediaHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: "37px",
    cursor: "pointer",
    "& svg": {
      fill: theme.palette.text.secondary,
    },
  },
  mediaHeaderText: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "29px",
    color: theme.palette.text.one,
    marginRight: "20px",
  },
  mediaWrapperHeader: {
    width: "100%",
    height: "36px",
    background: theme.palette.background.one,
    borderRadius: "10px",
    marginBottom: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    letterSpacing: "1px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  keyInfoText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "24px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    color: theme.palette.text.third,
    marginRight: "14px",
  },
}));

const StyledWeekViewTimeTableCell = styled(WeekView.TimeTableCell)(
  ({ theme }) => ({
    [`&.${classes.todayCell}`]: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.14),
      },
      "&:focus": {
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
      },
    },
    [`&.${classes.weekendCell}`]: {
      backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
      "&:hover": {
        backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
      },
      "&:focus": {
        backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
      },
    },
  })
);

const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)(
  ({ theme }) => ({
    "&.Cell-cell": {
      "& .Cell-dayView": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& .Cell-dayOfWeek": {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "13px",
          lineHeight: "19px",
          color: theme.palette.text.one,
        },
        "& .Cell-dayOfMonth": {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "13px",
          lineHeight: "19px",
          color: theme.palette.text.one,
        },
      },
    },
    [`&.${classes.today}`]: {
      backgroundColor: alpha(theme.palette.primary.main, 0.16),
    },
    [`&.${classes.weekend}`]: {
      backgroundColor: alpha(theme.palette.action.disabledBackground, 0.06),
    },
  })
);

const TimeTableCell = (props) => {
  const { startDate } = props;
  const date = new Date(startDate);

  if (date.getDate() === new Date().getDate()) {
    return (
      <StyledWeekViewTimeTableCell {...props} className={classes.todayCell} />
    );
  }

  return <StyledWeekViewTimeTableCell {...props} />;
};

const DayScaleCell = (props) => {
  const { startDate, today } = props;

  if (today) {
    return <StyledWeekViewDayScaleCell {...props} className={classes.today} />;
  }

  return <StyledWeekViewDayScaleCell {...props} />;
};

function Schedule() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const mediaData = useSelector((state) => state.medias);
  const playListData = useSelector((state) => state.playlist);
  const keyListData = useSelector((state) => state.keyList);

  const [mediaList, setMediaList] = useState([]);
  const [keyList, setKeyList] = useState([]);

  const [openKeyModal, setOpenKeyModal] = useState(false);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentFilter, setCurrentFilter] = useState({
    movie: "",
    status: "",
    startDate: null,
    endDate: null,
    isFavorite: false,
    playList: "",
  });
  const [scheduleMedia, setScheduleMedia] = useState({});
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [addedAppointment, setAddedAppointment] = useState({});
  const [appointmentChanges, setAppointmentChanges] = useState({});
  const [editingAppointment, setEditingAppointment] = useState(undefined);

  useEffect(() => {
    dispatch(getMedias());
    dispatch(getKeyList());
    document.title = "Pro Play Live - Schedule";
  }, []);

  useEffect(() => {
    if (mediaData.medias.length > 0) {
      setMediaList(mediaData.medias);
    }
  }, [mediaData]);

  useEffect(() => {
    if (keyListData.keyList.length > 0) {
      setKeyList(keyListData.keyList);
    }
  }, [keyListData]);

  const handleScheduleModalClose = () => {
    setScheduleModalOpen(false);
  };
  const handleCurrentDateChange = (currentDay) => {
    setCurrentDate(currentDay);
  };

  const handleMediaClick = (media) => {
    setScheduleMedia(media);
    setScheduleModalOpen(true);
  };

  const handleSchedule = (scheduleDateTime) => {
    const startDate = moment(scheduleDateTime).format("MMMM DD YYYY, hh:mm:ss");
    const endDate = moment(scheduleDateTime)
      .add(scheduleMedia.duration, "seconds")
      .format("MMMM DD YYYY, hh:mm:ss");
    const appointmentObj = {
      id: scheduleMedia.id,
      startDate,
      endDate,
      title: scheduleMedia.title,
    };

    const customData = data.slice();
    customData.push(appointmentObj);
    setData(customData);
    setScheduleModalOpen(false);
  };

  const changeAddedAppointment = (addedAppointment) => {
    setAddedAppointment(addedAppointment);
  };

  const changeAppointmentChanges = (appointmentChanges) => {
    setAppointmentChanges(appointmentChanges);
  };

  const changeEditingAppointment = (editingAppointment) => {
    setEditingAppointment(editingAppointment);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let customData = data.slice();
    if (added) {
      const startingAddedId =
        customData.length > 0 ? customData[customData.length - 1].id + 1 : 0;
      customData = [...customData, { id: startingAddedId, ...added }];
    }
    if (changed) {
      let isConflict = false;
      customData.forEach((item) => {
        if (Number(Object.keys(changed)[0]) !== item.id) {
          const itemStartDate = moment(item.startDate);
          const itemEndDate = moment(item.endDate);
          const targetStartDate = moment(
            changed[Object.keys(changed)[0]].startDate
          );
          const targetEndDate = moment(
            changed[Object.keys(changed)[0]].endDate
          );

          isConflict =
            isConflict ||
            targetStartDate.isBetween(itemStartDate, itemEndDate, "[]") ||
            targetEndDate.isBetween(itemStartDate, itemEndDate, "[]");
        }
      });

      if (!isConflict) {
        customData = customData.map((appointment) => {
          if (changed[appointment.id]) {
            const changedAppointment = {
              ...appointment,
              ...changed[appointment.id],
            };
            return changedAppointment;
          } else {
            return appointment;
          }
        });
      }
    }
    if (deleted !== undefined) {
      customData = customData.filter(
        (appointment) => appointment.id !== deleted
      );
    }

    setData(customData);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  const filteredMediaList = useMemo(() => {
    let filteredList = mediaList;

    if (currentFilter.playList !== "") {
      const targetIds = playListData.selectedPlayListMedias;
      filteredList = filteredList.filter((item) => targetIds.includes(item.id));
    } else {
      filteredList = mediaList;
    }
    if (currentFilter.movie) {
      filteredList = mediaList.filter(
        (item) =>
          item.description
            .replace(/\s/g, "")
            .toLowerCase()
            .includes(currentFilter.movie) ||
          item.title
            .replace(/\s/g, "")
            .toLowerCase()
            .includes(currentFilter.movie)
      );
    }
    if (currentFilter.status) {
      filteredList = filteredList.filter(
        (item) => item.status === currentFilter.status
      );
    }

    if (currentFilter.startDate && !currentFilter.endDate) {
      filteredList = filteredList.filter((item) =>
        moment(item.createdOn).isSameOrAfter(moment(currentFilter.startDate))
      );
    } else if (!currentFilter.startDate && currentFilter.endDate) {
      filteredList = filteredList.filter((item) =>
        moment(item.createdOn).isSameOrBefore(moment(currentFilter.endDate))
      );
    } else if (currentFilter.startDate && currentFilter.endDate) {
      filteredList = filteredList.filter((item) =>
        moment(item.createdOn).isBetween(
          moment(currentFilter.startDate),
          moment(currentFilter.endDate),
          "[]"
        )
      );
    }

    if (currentFilter.isFavorite) {
      filteredList = filteredList.filter((item) => item.isFavorite);
    }

    return filteredList;
  }, [currentFilter, mediaList]);

  const isFiltered = useMemo(() => {
    return (
      currentFilter.movie ||
      currentFilter.status ||
      currentFilter.startDate !== null ||
      currentFilter.endDate !== null
    );
  }, [currentFilter]);

  const medias = _.groupBy(filteredMediaList, "createdOn");

  const handleMediaDragStart = (e, media) => {
    setScheduleMedia(media);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setScheduleModalOpen(true);
  };

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleClearFilter = () => {
    setCurrentFilter({
      movie: "",
      status: "",
      startDate: null,
      endDate: null,
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="schedule-page">
        <div className="schedule-page-media">
          <div className={classes.mediaHeader}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              onClick={() => setOpenFilter(true)}
            >
              <div className={classes.mediaHeaderText}>Filter Media</div>
              <Search />
            </Stack>
            {isFiltered && (
              <IconButton onClick={() => handleClearFilter()}>
                <HighlightOff />
              </IconButton>
            )}
          </div>
          <div className="schedule-page-media-container">
            {Object.keys(medias).map((mediaKey, index) => (
              <div className="media-wrapper" key={index}>
                <div className={classes.mediaWrapperHeader}>
                  {moment().isSame(moment(mediaKey), "day")
                    ? "Today"
                    : moment(mediaKey).format("ll")}
                </div>
                <div className="media-wrapper-items">
                  {medias[mediaKey].map((media, index) => (
                    <div
                      className="media-wrapper-item"
                      key={index}
                      onClick={() => handleMediaClick(media)}
                      onDragStart={(e) => handleMediaDragStart(e, media)}
                      draggable
                    >
                      <img src={media.thumbnailUrl} alt="" />
                      <div className="media-wrapper-item-text">
                        {media.description}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className="schedule-page-timeline"
          onDrop={(e) => handleDrop(e)}
          onDragOver={(e) => handleDragOver(e)}
        >
          <Stack
            sx={{ height: "100%" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Scheduler data={data} height={715}>
              <ViewState
                currentDate={currentDate}
                onCurrentDateChange={handleCurrentDateChange}
                defaultCurrentViewName="Week"
              />
              <EditingState
                onCommitChanges={commitChanges}
                addedAppointment={addedAppointment}
                onAddedAppointmentChange={changeAddedAppointment}
                appointmentChanges={appointmentChanges}
                onAppointmentChangesChange={changeAppointmentChanges}
                editingAppointment={editingAppointment}
                onEditingAppointmentChange={changeEditingAppointment}
              />
              <EditRecurrenceMenu />
              {/* <DayView startDayHour={0} endDayHour={24} /> */}
              <WeekView
                startDayHour={0}
                endDayHour={24}
                timeTableCellComponent={TimeTableCell}
                dayScaleCellComponent={DayScaleCell}
              />
              <Toolbar />
              <DateNavigator />
              <Appointments />
              <AppointmentTooltip showOpenButton showDeleteButton />
              <AppointmentForm />
              <TodayButton />
              <ViewSwitcher />
              <DragDropProvider allowResize={() => false} />
            </Scheduler>
            <div className="bottom-keys-container">
              <div className="bottom-keys-container-title">Key:</div>
              {keyList.length > 0 &&
                keyList.map((keyItem, index) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    key={index}
                    sx={{ marginRight: "14px" }}
                    className="bottom-key-info"
                  >
                    <div
                      className="bottom-key-info-color"
                      style={{ background: keyItem.color }}
                    />
                    <div className={classes.keyInfoText}>{keyItem.text}</div>
                  </Stack>
                ))}
              <IconButton
                onClick={() => setOpenKeyModal(true)}
                className="keys-open-btn"
              >
                <SettingsIcon />
              </IconButton>
            </div>
          </Stack>
        </div>

        <ScheduleModal
          currentMedia={scheduleMedia}
          onModalClose={handleScheduleModalClose}
          handleSchedule={handleSchedule}
          modalOpen={scheduleModalOpen}
        />

        <FilterModal
          handleFilterClose={handleFilterClose}
          openFilter={openFilter}
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
        />
        <KeyControlModal
          openKeyModal={openKeyModal}
          handleModalClose={() => setOpenKeyModal(false)}
        />
      </div>
    </LocalizationProvider>
  );
}

export default Schedule;
