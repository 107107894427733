import { Typography } from "@mui/material";

function Monitize() {
  document.title = "Pro Play Live - Monitize";
  return (
    <div className="montize">
      <Typography variant="h4" color="#052442" mt={3} mb={3}>
        Monitize Page
      </Typography>
    </div>
  );
}

export default Monitize;
