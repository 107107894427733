import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";

import { Box, Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import "./index.scss";

import { getKeyList } from "../../store/keyList";
import { updateMedia } from "../../store/medias";

const useStyles = makeStyles((theme) => ({
  title: {
    width: "490px",
    height: "50px",
    background: theme.palette.background.four,
    borderRadius: "15px",
    paddingLeft: "23px",
    border: "none",
    marginBottom: "25px",
    color: theme.palette.text.primary,
  },
  desc: {
    width: "490px",
    height: "105px",
    background: theme.palette.background.four,
    borderRadius: "15px",
    padding: "15px 21px",
    border: " none",
    marginBottom: "25px",
    color: theme.palette.text.primary,
  },
  itemText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "24px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    color: theme.palette.text.third,
    marginRight: "10px",
    minWidth: "100px",
    textAlign: "right",
  },
  itemValue: {
    width: "243px",
    height: "43px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "15px",
    background: theme.palette.background.four,
    borderRadius: "15px",
  },
  itemValueText: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "29px",
    color: theme.palette.text.secondary,
  },
  keys: {
    width: "194px",
    height: "43px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "15px",
    background: theme.palette.background.four,
    borderRadius: "15px",
  },
}));

function ScheduleModal({
  currentMedia,
  onModalClose,
  handleSchedule,
  modalOpen,
  isEdit = false,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const keyListData = useSelector((state) => state.keyList);
  const [scheduleDateTime, setScheduleDateTime] = useState(new Date());
  const [savingInfo, setSavingInfo] = useState(currentMedia);
  const [seriesEndsDate, setSeriesEndsDate] = useState(new Date());
  const [keys, setKeys] = useState([]);
  const [currentKey, setCurrentKey] = useState(null);
  const handleScheduleDateTimeChange = (newValue) => {
    setScheduleDateTime(newValue);
  };
  const handleSeriesEndsDateChange = (newValue) => {
    setSeriesEndsDate(newValue);
  };

  useEffect(() => {
    dispatch(getKeyList());
  }, []);

  useEffect(() => {
    if (currentMedia !== savingInfo) {
      setSavingInfo(currentMedia);
    }
  }, [currentMedia]);

  useEffect(() => {
    if (keyListData.keyList.length > 0) {
      setKeys(keyListData.keyList);
    }
  }, [keyListData]);

  const handleSelectKey = (e) => {
    const targetKey = keys.find((item) => item.id === Number(e.target.value));
    setSavingInfo({
      ...savingInfo,
      key: targetKey.id,
    });
    setCurrentKey(targetKey);
  };

  const handleSave = () => {
    if (isEdit) {
      dispatch(updateMedia(savingInfo));
      onModalClose();
    } else {
      handleSchedule(scheduleDateTime);
    }
  };

  const handleChangeInput = (e) => {
    setSavingInfo({
      ...savingInfo,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Dialog onClose={onModalClose} maxWidth="auto" open={modalOpen}>
      <DialogContent>
        <Box className="schedule-media-dlg">
          <Typography variant="h5" sx={{ marginBottom: "10px" }}>
            Schedule Video
          </Typography>
          <Stack direction="row">
            <Stack className="schedule-media-dlg-img-wrapper">
              <img
                className="schedule-media-dlg-img"
                src={savingInfo.thumbnailUrl}
                alt=""
              />
            </Stack>
            <Stack direction="column" alignItems="flex-start">
              <input
                className={classes.title}
                value={savingInfo.title}
                name="title"
                readOnly={!isEdit}
                onChange={handleChangeInput}
              />
              <textarea
                className={classes.desc}
                value={savingInfo.description}
                readOnly={!isEdit}
                name="description"
                onChange={handleChangeInput}
              />
              {!isEdit && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ marginBottom: "18px" }}
                >
                  <div className={classes.itemText}>scheduled</div>
                  <DateTimePicker
                    value={scheduleDateTime}
                    onChange={handleScheduleDateTimeChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ marginBottom: "18px" }}
              >
                <div className={classes.itemText}>status</div>
                <div className={classes.itemValue}>
                  <div
                    className={`schedule-media-dlg-item-status ${savingInfo.status}`}
                  />
                  <div className={classes.itemValueText}>
                    {savingInfo.status}
                  </div>
                </div>
              </Stack>
              {!isEdit && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ marginBottom: "18px" }}
                >
                  <div className={classes.itemText}>recurrence</div>
                  <div className={classes.itemValue}>
                    <select>
                      <option value="once">Once</option>
                      <option value="hourly">Hourly</option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                    </select>
                  </div>
                </Stack>
              )}
              {!isEdit && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ marginBottom: "18px" }}
                >
                  <div className={classes.itemText}>SERIES ENDS</div>
                  <DesktopDatePicker
                    value={seriesEndsDate}
                    onChange={handleSeriesEndsDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ marginBottom: "18px" }}
              >
                <div className={classes.itemText}>key</div>
                <div
                  className="schedule-media-dlg-item-key"
                  style={{ background: currentKey ? currentKey.color : "gray" }}
                />
                {keys.length > 0 && (
                  <div className={classes.keys}>
                    <select
                      onChange={(e) => handleSelectKey(e)}
                      value={savingInfo.key >= 0 ? savingInfo.key : ""}
                    >
                      <option value="" disabled></option>
                      {keys.map((keyItem, index) => (
                        <option value={keyItem.id} key={index}>
                          {keyItem.text}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onModalClose}>Cancel</Button>
        <Button onClick={() => handleSave()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ScheduleModal;
