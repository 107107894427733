import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { mediaList } from "../data/mediaList";

const initialState = {
  isLoading: false,
  medias: [],
  error: null,
};

const isLoading = (action) => {
  return [
    "medias/pending",
    "medias/update/pending",
    "medias/update/individual/pending",
  ].includes(action.type);
};

const isFinishLoading = (action) => {
  return [
    "medias/fulfilled",
    "medias/rejected",
    "medias/update/fulfilled",
    "medias/update/rejected",
    "medias/update/individual/fulfilled",
    "medias/update/individual/rejected",
  ].includes(action.type);
};

const isRejected = (action) => {
  return [
    "medias/rejected",
    "medias/update/rejected",
    "medias/update/individual/rejected",
  ].includes(action.type);
};

export const getMedias = createAsyncThunk(
  "medias",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          if (getState().medias.medias.length < 1) {
            resolve(mediaList);
          } else {
            const customMedias = getState().medias.medias.slice();
            resolve(customMedias);
          }
        }, 500)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const updateMedias = createAsyncThunk(
  "medias/update",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          resolve(values.slice());
        }, 500)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const updateMedia = createAsyncThunk(
  "medias/update/individual",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          let customMedias = getState().medias.medias.slice();
          const targetIndex = customMedias.findIndex(
            (item) => item.id === values.id
          );
          customMedias[targetIndex] = { ...values };
          resolve(customMedias);
        }, 500)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const getMediasSlice = createSlice({
  name: "medias",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getMedias.fulfilled, (state, action) => {
        state.medias = action.payload;
      })
      .addCase(updateMedias.fulfilled, (state, action) => {
        state.medias = action.payload;
      })
      .addCase(updateMedia.fulfilled, (state, action) => {
        state.medias = action.payload;
      })
      .addMatcher(isLoading, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addMatcher(isFinishLoading, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default getMediasSlice.reducer;
