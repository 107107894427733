import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { Box, Stack, Typography } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    "&.MuiDialogContent-root": {
      padding: "36px 40px",
      width: "606px",
      height: "auto",
    },
  },
  addPlayListName: {
    width: "250px",
    height: "51px",
    paddingLeft: "20px",
    background: theme.palette.background.eleven,
    borderRadius: "15px",
    border: "none",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "29px",
    color: theme.palette.text.secondary,
    marginBottom: "43px",
  },
  editChannelSaveBtn: {
    width: "130px",
    height: "43px",
    background: "#377DFF",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "29px",
    border: "none",
    color: theme.palette.text.white,
    cursor: "pointer",
    "&:disabled": {
      background: "lightgray",
      cursor: "not-allowed",
    },
  },
  editChannelCancelBtn: {
    width: "130px",
    height: "43px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    border: "none",
    lineHeight: "29px",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  highlight: {
    color: theme.palette.text.highlight,
  },
}));

function DeleteMediaConfirmModal({
  handleModalClose,
  openConfirmModal,
  onConfirmDelete,
}) {
  const classes = useStyles();
  const [confirmText, setConfirmText] = useState("");

  const handleConfirmTextInput = (e) => {
    setConfirmText(e.target.value);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    handleModalClose();
  };

  const handleConfirmDelete = (e) => {
    e.preventDefault();
    onConfirmDelete();
    handleModalClose();
  };

  return (
    <Dialog onClose={handleModalClose} maxWidth="auto" open={openConfirmModal}>
      <DialogContent className={classes.modalContent}>
        <Box>
          <Typography
            variant="h5"
            sx={{
              marginBottom: "9px",
              fontFamily: "Inter",
              fontSize: "32px",
              lineHeight: "29px",
              fontWeight: "600",
            }}
          >
            Delete Videos
          </Typography>
          <Typography
            variant="h5"
            sx={{
              marginBottom: "9px",
              fontFamily: "Inter",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "25px",
            }}
          >
            Deleting the videos will permanently remove them from your hosted
            cloud account. Any files currently in use or scheduled for use will
            not play.
            <br />
            <br />
            Type <span className={classes.highlight}>delete videos</span> in the
            field and submit the changes.
          </Typography>
          <Box component="form" onSubmit={handleConfirmDelete}>
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.editChannelWrapper}
            >
              <input
                name="text"
                className={classes.addPlayListName}
                value={confirmText}
                onChange={handleConfirmTextInput}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ marginBottom: "18px" }}
              >
                <button
                  className={classes.editChannelSaveBtn}
                  type="submit"
                  disabled={confirmText !== "delete videos"}
                >
                  Save
                </button>
                <button
                  className={classes.editChannelCancelBtn}
                  onClick={(e) => handleCancel(e)}
                >
                  Cancel
                </button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteMediaConfirmModal;
