import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeProvider, useTheme, createTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { CssBaseline } from "@mui/material";

import Header from "../Header";
import LeftNav from "../LeftNav";

import "./index.scss";
import { common, grey } from "@mui/material/colors";

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    background: {
      ...(mode === "light"
        ? {
            primary: common.white,
            secondary: common.white,
            third: "#F7F7F7",
            one: "#F1F1F1",
            two: "#fbfbfb",
            three: "#F3F3F3",
            four: "#efefef",
            five: "#E6E9EC",
            six: "#DADADA",
            seven: "#E5F7EF",
            eight: "#FFEFEB",
            nine: "#38CB89",
            ten: "#FF5630",
            eleven: "#EFEFEF",
            twelve: "#F4F8FF",
            thirteen: "#FAFBFC",
          }
        : {
            primary: common.black,
            secondary: common.black,
            third: common.black,
            one: grey[600],
            two: grey[800],
            three: grey[600],
            four: grey[600],
            five: grey[600],
            six: grey[600],
            seven: grey[600],
            eight: grey[600],
            nine: grey[600],
            ten: grey[600],
            eleven: grey[600],
            twelve: grey[600],
            thirteen: grey[600],
          }),
    },
    text: {
      ...(mode === "light"
        ? {
            primary: common.black,
            secondary: "#B0B7C3",
            third: "#4E5D78",
            one: "#323B4B",
            two: "#4C5462",
            white: "white",
            black: "black",
            highlight: "#323B4B",
          }
        : {
            primary: common.white,
            secondary: common.white,
            third: common.white,
            one: common.white,
            two: common.white,
            white: "white",
            black: "black",
            highlight: "#377DFF",
          }),
    },
    dropdown: {
      ...(mode === "light"
        ? {
            primary: "#FAFBFC",
            secondary: "#F4F8FF",
          }
        : {
            primary: common.black,
            secondary: common.black,
          }),
    },
    badge: {
      ...(mode === "light"
        ? {
            primary: "#D8D8D8",
            secondary: "#F4F8FF",
          }
        : {
            primary: common.white,
            secondary: common.white,
          }),
    },
    border: {
      ...(mode === "light"
        ? {
            primary: "#B8B0B0",
          }
        : {
            primary: common.white,
          }),
    },
    error: {
      main: "#FF0000",
    },
    alert: {
      main: "#FF5630",
    },
  },
});

function Layout() {
  const themeData = useSelector((state) => state.theme);
  const theme = useMemo(
    () => createTheme(getDesignTokens(themeData.mode)),
    [themeData]
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header />
        <LeftNav />
        <Box
          component="main"
          sx={{ flexGrow: 1, height: "100%" }}
          className="main-container"
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Layout;
