import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { keys } from "../data/keys";

const initialState = {
  isLoading: false,
  keyList: [],
  error: null,
};

const isLoading = (action) => {
  return [
    "keyList/pending",
    "channels/update/pending",
    "channels/create/pending",
    "channels/remove/pending",
  ].includes(action.type);
};

const isFinishLoading = (action) => {
  return [
    "keyList/fulfilled",
    "keyList/rejected",
    "keyList/update/fulfilled",
    "keyList/update/rejected",
    "keyList/create/fulfilled",
    "keyList/create/rejected",
    "keyList/remove/fulfilled",
    "keyList/remove/rejected",
  ].includes(action.type);
};

const isRejected = (action) => {
  return [
    "keyList/rejected",
    "keyList/update/rejected",
    "keyList/create/rejected",
    "keyList/remove/rejected",
  ].includes(action.type);
};

export const getKeyList = createAsyncThunk(
  "keyList",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          if (getState().keyList.keyList.length < 1) {
            resolve(keys);
          } else {
            resolve(getState().keyList.keyList);
          }
        }, 500)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const removeKey = createAsyncThunk(
  "keyList/remove",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          const customKeyList = getState().keyList.keyList.slice();
          const index = customKeyList.findIndex(
            (item) => item.id === values.id
          );
          customKeyList.splice(index, 1);
          resolve(customKeyList);
        }, 1000)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const updateKeyList = createAsyncThunk(
  "keyList/update",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          const customKeyList = getState().keyList.keyList.slice();
          const index = customKeyList.findIndex(
            (item) => item.id === values.id
          );
          customKeyList[index] = {
            ...values,
          };
          resolve(customKeyList);
        }, 1000)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const createKey = createAsyncThunk(
  "keyList/create",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          const customKeyList = getState().keyList.keyList.slice();
          customKeyList.push(values);
          resolve(customKeyList);
        }, 1000)
      );
      return data;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const getKeyListSlice = createSlice({
  name: "keyList",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getKeyList.fulfilled, (state, action) => {
        state.keyList = action.payload;
      })
      .addCase(updateKeyList.fulfilled, (state, action) => {
        state.keyList = action.payload;
      })
      .addCase(removeKey.fulfilled, (state, action) => {
        state.keyList = action.payload;
      })
      .addCase(createKey.fulfilled, (state, action) => {
        state.keyList = action.payload;
      })
      .addMatcher(isLoading, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addMatcher(isFinishLoading, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default getKeyListSlice.reducer;
